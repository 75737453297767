import { SlOptions } from 'react-icons/sl'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import FloatingCard from '@/components/base/FloatingCard/FloatingCard'
import Spinner from '@/components/base/Spinner'
import { useAuthStore } from '@/dataStores/auth'
import useDeleteComment from '@/hooks/useDeleteComment'
import useReportComment from '@/hooks/useReportComment'

const CommentOptions = ({ uuid, AuthorUUID, onDelete }): JSX.Element => {
  const [user] = useAuthStore((state) => [state.user])
  const { handleClick: handleDelete, loading: loadingDelete } = useDeleteComment({
    uuid,
    onDelete,
  })
  const { handleClick: handleReport, loading: loadingReport } = useReportComment({
    uuid,
  })
  const isOP =
    AuthorUUID === user.UUID ||
    (user.Companies?.length > 0 ? user.Companies.map((c) => c.UUID).indexOf(AuthorUUID) > -1 : null)

  const optionsLoading = loadingDelete || loadingReport

  return (
    <div className="relative">
      <FloatingCard
        renderTrigger={(toggle) => (
          <Button
            as="div"
            size={ButtonSize.Medium}
            className="flex flex-row items-center justify-center"
            icon={() => {
              return !optionsLoading ? (
                <SlOptions />
              ) : (
                <div>
                  <Spinner size={14} className="text-gray-800" />
                </div>
              )
            }}
            onClick={toggle}
            appearance={ButtonAppearance.White}
          />
        )}
      >
        <FloatingCard.Button onClick={handleReport} icon="Flag" help={`Avísanos si esta publicación es inadecuada.`}>
          <span>Reportar</span>
        </FloatingCard.Button>

        {user.IsAdmin || isOP ? (
          <FloatingCard.Button onClick={handleDelete} icon="Trash" help={`Deberás confirmar la acción.`}>
            <span>Eliminar</span>
          </FloatingCard.Button>
        ) : null}
      </FloatingCard>
    </div>
  )
}

export default CommentOptions
