import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import { useState } from 'react'

import Button, { ButtonAppearance } from '@/components/base/Button'
import CustomIcon, { IconsTypes } from '@/components/base/Icons/CustomIcon'
import useLongPress from '@/hooks/ui/useLongPress'
import { useOuterClick } from '@/hooks/ui/useOuterClick'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import { isCompanyType } from '@/utils/companyTypes'
import { reactions } from '@/utils/reactions'

export const LikeButton = ({ disabled, onClick, post, reaction, setReaction, setLikeCount }) => {
  const [open, setOpen] = useState(false)

  const { author } = useSunetAuthors()

  const ownLike = post.Included.OwnLike

  const currentReaction = reactions.find((r) => r.value === reaction) || ''
  const isLike = reaction === 'like'

  const getReactionIcon = () => {
    let Component
    if (!reaction) {
      Component = <CustomIcon className={clsx('text-black')} name={IconsTypes.commentHeart} />
    } else {
      if (isLike) {
        Component = <CustomIcon className={clsx('text-primary')} name={IconsTypes.commentHeart} />
      }
      if (!isLike) {
        const IconComponent = currentReaction.icon
        Component = <IconComponent size={20} />
      }
    }
    return Component
  }

  const handlePostCount = (react) => {
    if (reaction && react !== reaction) {
      return
    }
    if (react === reaction) {
      setLikeCount((prev) => prev - 1)
      setReaction('')
    }

    if (!reaction) {
      setLikeCount((prev) => Math.max(prev + 1, post.Included.LikeCount))
    }
  }

  const openReactions = () => {
    setOpen(true)
  }

  const closeReactions = () => {
    setOpen(false)
  }

  const innerRef = useOuterClick((ev) => closeReactions())

  const backspaceLongPress = useLongPress(openReactions, 500)

  return (
    <div className="relative">
      <Transition
        show={open}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="absolute -top-24 sm:-top-12 bg-white rounded-lg p-2 flex sm:flex-row flex-col gap-4 shadow-lg"
          onMouseLeave={() => setOpen(false)}
        >
          <div ref={innerRef} className="flex items-center gap-2 sm:gap-4 w-52 sm:w-full flex-wrap sm:flex-nowrap">
            <button
              onClick={() => {
                onClick('like')
                handlePostCount('like')
                setOpen(false)
              }}
              className="transition ease-in-out delay-100 hover:-translate-y-2 hover:scale-110"
            >
              <CustomIcon size={24} className="text-primary" name={IconsTypes.commentHeart} />
            </button>
            {reactions.map((r) => {
              const Component = r.icon

              return (
                <button
                  onClick={() => {
                    onClick(r.value)
                    handlePostCount(r.value)
                    setOpen(false)
                  }}
                  key={r.value}
                  className="transition ease-in-out delay-100 hover:-translate-y-2 hover:scale-110"
                >
                  <Component size={28} />
                </button>
              )
            })}
          </div>
        </div>
      </Transition>

      <Button
        {...backspaceLongPress}
        appearance={ButtonAppearance.Gray}
        onMouseEnter={() => setOpen(true)}
        onClick={() => {
          if (!reaction) {
            onClick('like')
            handlePostCount('like')
          } else {
            onClick(reaction)
            handlePostCount(reaction)
          }
        }}
        className={clsx('px-4 h-8', disabled ? 'opacity-50' : null)}
        disabled={disabled}
      >
        <div className={clsx('flex items-center', ownLike && !isCompanyType(author?.Type) ? 'text-brand' : null)}>
          {getReactionIcon()}
        </div>
      </Button>
    </div>
  )
}
