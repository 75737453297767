import { TokenSmile } from '@/components/base/Icons/svg/wallet/TokenSmile'
import { Flower } from '@/components/shared/Reactions/Flower'
import { Leaf } from '@/components/shared/Reactions/Leaf'
import { Light } from '@/components/shared/Reactions/Light'
import { Panel } from '@/components/shared/Reactions/Panel'
import { Plant } from '@/components/shared/Reactions/Plant'
import { Recycle } from '@/components/shared/Reactions/Recycle'
import { Support } from '@/components/shared/Reactions/Support'
import { Tree } from '@/components/shared/Reactions/Tree'
import { Water } from '@/components/shared/Reactions/Water'

export const reactions = [
  {
    value: 'leaf',
    icon: Leaf,
  },
  {
    value: 'tree',
    icon: Tree,
  },
  {
    value: 'water',
    icon: Water,
  },
  {
    value: 'recycle',
    icon: Recycle,
  },
  {
    value: 'light',
    icon: Light,
  },
  {
    value: 'flower',
    icon: Flower,
  },
  {
    value: 'support',
    icon: Support,
  },

  {
    value: 'panel',
    icon: Panel,
  },
  {
    value: 'plant',
    icon: Plant,
  },
  {
    value: 'token',
    icon: TokenSmile,
  },
]
