import { SvgIcon } from '@/components/base/Icons/CustomIcon'

export const Tree = ({ size }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 228 257" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M202.907 62.9055C202.113 40.1795 183.457 22.002 160.541 22.002C156.94 22.002 153.448 22.455 150.11 23.3C143.074 9.6145 128.826 0.25 112.385 0.25C110.618 0.25 108.877 0.358 107.167 0.5695C69.7294 21.2985 83.7759 89.8345 113.75 94.0225V157.281C118.473 175.536 135.054 189.02 154.787 189.02C177.291 189.02 195.685 171.492 197.096 149.348C214.671 142.142 227.049 124.871 227.049 104.7C227.049 86.8365 217.34 71.24 202.907 62.9055Z"
        fill="#60DAA8"
      />
      <path
        d="M113.75 94.0223V157.28C113.436 156.075 113.178 154.854 112.972 153.613C109.634 173.701 92.1829 189.02 71.1519 189.02C48.4674 189.02 29.9449 171.208 28.8014 148.807H29.1364C12.2364 141.308 0.450928 124.382 0.450928 104.7C0.450928 87.4963 9.45493 72.4043 23.0119 63.8638C23.3004 40.6948 42.1629 22.0023 65.3989 22.0023C68.6284 22.0023 71.7654 22.3733 74.7839 23.0583C81.0989 10.9693 93.0544 2.30034 107.167 0.569336C81.7884 30.9443 96.2264 80.5523 113.75 94.0223Z"
        fill="#00CE8E"
      />
      <path
        d="M139.824 138.47C130.738 164.683 130.738 193.193 139.824 219.411L143.656 230.46C103.787 241.283 93.077 151.865 105.339 132.453V94.6196C105.339 92.2966 106.281 90.1896 107.801 88.6651C109.325 87.1456 111.427 86.2031 113.75 86.2031C118.396 86.2031 122.162 89.9686 122.162 94.6196V133.767L131.717 107.487C132.994 103.969 136.337 101.625 140.082 101.625C146.201 101.625 150.497 107.662 148.493 113.441L139.824 138.47Z"
        fill="#E7896E"
      />
      <path
        d="M141.348 256.25H86.1514C80.6504 256.25 76.7924 250.826 78.5899 245.629L87.6759 219.411C96.7619 193.193 96.7619 164.682 87.6759 138.469L79.2129 114.044C78.8574 113.019 78.6929 111.983 78.6929 110.974C78.6929 106.003 82.7104 101.625 88.0574 101.625C92.0494 101.625 95.6034 104.159 96.9014 107.94L105.338 132.453C99.6259 157.404 108.666 229.27 143.656 230.46L148.91 245.629C150.707 250.826 146.849 256.25 141.348 256.25Z"
        fill="#DD6642"
      />
      <path
        d="M72.7711 81.1689C81.122 81.1689 87.8916 75.778 87.8916 69.1279C87.8916 62.4779 81.122 57.0869 72.7711 57.0869C64.4203 57.0869 57.6506 62.4779 57.6506 69.1279C57.6506 75.778 64.4203 81.1689 72.7711 81.1689Z"
        fill="#FFA1AC"
      />
      <path
        d="M154.729 81.1689C163.08 81.1689 169.85 75.778 169.85 69.1279C169.85 62.4779 163.08 57.0869 154.729 57.0869C146.378 57.0869 139.609 62.4779 139.609 69.1279C139.609 75.778 146.378 81.1689 154.729 81.1689Z"
        fill="#FFA1AC"
      />
      <path
        d="M81.5831 61.2865C79.5116 61.2865 77.8331 59.6075 77.8331 57.5365V51.3555C77.8331 49.2845 79.5116 47.6055 81.5831 47.6055C83.6546 47.6055 85.3331 49.2845 85.3331 51.3555V57.5365C85.3331 59.6075 83.6546 61.2865 81.5831 61.2865Z"
        fill="#495560"
      />
      <path
        d="M145.917 61.2865C143.845 61.2865 142.167 59.6075 142.167 57.5365V51.3555C142.167 49.2845 143.845 47.6055 145.917 47.6055C147.988 47.6055 149.667 49.2845 149.667 51.3555V57.5365C149.667 59.6075 147.988 61.2865 145.917 61.2865Z"
        fill="#495560"
      />
      <path
        d="M113.75 64.9663C109.416 64.9663 105.295 63.0893 102.443 59.8168C101.082 58.2553 101.245 55.8868 102.806 54.5258C104.367 53.1648 106.736 53.3273 108.097 54.8888C109.524 56.5268 111.585 57.4658 113.751 57.4658C115.916 57.4658 117.977 56.5268 119.404 54.8888C120.766 53.3273 123.134 53.1648 124.695 54.5258C126.257 55.8863 126.419 58.2553 125.059 59.8168C122.206 63.0893 118.084 64.9663 113.75 64.9663Z"
        fill="#495560"
      />
    </svg>
  )
}
