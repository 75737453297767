import { useState } from 'react'
import { toast } from 'react-hot-toast'

import { useAlert } from '@/providers/alert/context'
import { reportCommentByCommentUUIDAndReason } from '@/services/postsService'

type Props = {
  uuid: string
}

type ReturnProps = {
  handleClick: () => void
  loading?: boolean
}

const useReportComment = ({ uuid }: Props): ReturnProps => {
  const { showConfirm } = useAlert()

  const [loading, setLoading] = useState<boolean>(false)

  const handleConfirm = async (reason: string) => {
    setLoading(true)
    try {
      await reportCommentByCommentUUIDAndReason(uuid, reason)
      toast.success(`Tu reporte fue enviado con éxito. ${reason}.`)
    } catch (error) {
      console.error('TODO: REPORT COMMENT ERROR')
    } finally {
      setLoading(false)
    }
  }

  const handleClick = () => {
    showConfirm({
      title: `Reportar publicación`,
      content: <span>Selecciona alguna de las opciones de más abajo para reportar la publicación.</span>,
      options: [
        { value: 'SPAM', label: 'SPAM' },
        {
          value: 'Desnudos o actividad sexual',
          label: `Desnudos o actividad sexual`,
        },
        {
          value: 'Lenguaje o símbolos que incitan al odio',
          label: `Lenguaje o símbolos que incitan al odio`,
        },
        {
          value: 'Violencia u organizaciones peligrosas',
          label: `Violencia u organizaciones peligrosas`,
        },
        {
          value: 'Venta de artículos ilegales o regulados',
          label: `Venta de artículos ilegales o regulados`,
        },
        { value: 'Bullying o acoso', label: `Bullying o acoso` },
        {
          value: 'Infracción a la propiedad intelectual',
          label: `Infracción a la propiedad intelectual`,
        },
        {
          value: 'Suicidio, autolesión o trastornos alimenticios',
          label: `Suicidio, autolesión o trastornos alimenticios`,
        },
        { value: 'Fraude', label: `Fraude` },
        { value: 'Información falsa', label: `Información falsa` },
        { value: 'Simplemente no me gusta', label: `Simplemente no me gusta` },
      ],
      onConfirm: handleConfirm,
      zIndex: 51,
    })
  }

  return { handleClick, loading }
}

export default useReportComment
