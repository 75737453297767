import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import { FiSend } from 'react-icons/fi'

import Spinner from '@/components/base/Spinner'
import useCommentSubmit from '@/hooks/useCommentSubmit'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import useValues from '@/hooks/useValues'

import Avatar from '../Avatar'
import MentionableInput from '../Feed/PostForm/MentionableInput'

export default function CommentForm({ postUUID, mutatePost }) {
  const router = useRouter()
  const inputRef = useRef(null)
  const buttonRef = useRef()
  const { author } = useSunetAuthors()

  const { values, key, updateValues, reset } = useValues({
    Content: '',
  })
  const onSuccess = (commentUUID) => {
    mutatePost()
    if (router.pathname !== '/groups/[groupUUID]' && router.pathname === '/') {
      router.push(`p/${postUUID}?commentUUID=${commentUUID}`)
    }
    reset()
  }

  const { handleSubmit, loading } = useCommentSubmit({ postUUID, author, onSuccess: onSuccess })

  useEffect(() => {
    inputRef.current?.addEventListener('keydown', function (e) {
      if (e.keyCode === 13 && (e.metaKey || e.ctrlKey)) {
        buttonRef?.current?.click()
      }
    })
  }, [key])

  return (
    <form onSubmit={handleSubmit(values)} className="flex items-center -mx-1 text-gray-800 mt-4">
      <div className="flex px-1">
        <Avatar
          text={author.Name}
          className="w-8 h-8 text-white text-xs !rounded-full"
          image={author.Photo?.Thumbnail}
          showBorder={false}
        />
      </div>
      <div className="flex flex-1 px-1 min-w-0 relative">
        <div
          className="relative w-full flex items-center border border-gray-300 px-1 sm:px-4 comment"
          style={{ borderRadius: 17 }}
        >
          <div className="flex-1 min-w-0">
            <MentionableInput
              ref={inputRef}
              key={key}
              value={values.Content}
              onChange={(value, plainValue, mentions) =>
                updateValues({ Content: value, PlainContent: plainValue, Mentions: mentions })
              }
              name="Content"
              placeholder={`Escribe un comentario`}
              required
              autoComplete="off"
              disabled={loading}
            />
          </div>
          {/* <EmojiButton
            onSelect={(emoji) => {
              const value = values.Content || ''
              updateValues({ Content: value + emoji.native, PlainContent: inputRef.current.value + emoji.native })
            }}
          /> */}
          <button
            ref={buttonRef}
            type="submit"
            disabled={loading}
            className="w-6 h-8 inline-flex items-center justify-center  focus:outline-none"
          >
            {loading ? <Spinner size={15} className="text-brand" /> : <FiSend className="text-brand" />}
          </button>
        </div>
      </div>
    </form>
  )
}
