import { useState } from 'react'
import { mutate } from 'swr'

import { postURLEndpoint, toggleLikeByPostUUIDAndUserUUID } from '@/services/postsService'
import { Post } from '@/ts/types/post.types'
import { handleError } from '@/utils/alerts'
import { mutateTokens } from '@/utils/mutation'

import useSunetAuthors from './useSunetAuthors'

type Props = {
  post: Post
}

type ReturnProps = {
  handleToggle: (slug: string) => Promise<void>
  loading?: boolean
}

const useLike = ({ post }: Props): ReturnProps => {
  const [loading, setLoading] = useState<boolean>(false)
  const { author } = useSunetAuthors()

  const handleToggle = async (slug: string) => {
    const newOwnLike = !post.Included.OwnLike
    const oldLikeCount = post.Included.LikeCount
    const newLikeCount = newOwnLike ? oldLikeCount + 1 : oldLikeCount - 1

    setLoading(true)
    try {
      await toggleLikeByPostUUIDAndUserUUID(post.UUID, author.UUID, slug)

      await mutateTokens(author.UUID)
      await mutate([author.UUID, '/gamification/ranking?AuthorUUID&include=Level'])
      await mutate(
        {
          page: postURLEndpoint,
          postUUID: post.UUID,
        },
        {
          ...post,
          Included: {
            ...post.Included,
            LikeCount: newLikeCount,
            OwnLike: newOwnLike,
          },
        }
      ) // Pos
    } catch (error) {
      handleError(error, 'post')
    } finally {
      setLoading(false)
    }
  }

  return { handleToggle, loading }
}

export default useLike
