import { siteURL } from '../config'

export const getDescText = (postType, postContent, postMetaData, locale = 'es') => {
  if (postType === 'lesson') {
    return postContent.split('~')[6]
  } else if (postType === 'initiative') {
    return postContent.split('~')[1]
  }
  if (postMetaData?.Type === 'activity') {
    return postMetaData.Data.Description[locale]
  }
  return postContent
}

export const getTitleText = (postType, postContent, postMetaData, locale = 'es') => {
  if (postType === 'lesson') {
    return (locale === 'en' ? `Lesson ~ ` : 'Aprendizaje ~ ') + ' ' + postContent.split('~')[7]
  } else if (postType === 'initiative') {
    return (locale === 'en' ? `Initiative ~ ` : 'Iniciativa ~ ') + ' ' + postContent.split('~')[0]
  }

  if (postMetaData?.Type === 'activity') {
    return (locale === 'en' ? `Event ~ ` : 'Evento ~ ') + ' ' + postMetaData.Data.Name[locale]
  }
  return locale === 'en' ? `Sunet Post  ` : 'Publicación de Sunet  '
}

export const getPicture = (postMeta) => {
  if (postMeta?.Type === 'image' && postMeta?.Data[0]) {
    return postMeta?.Data[0].URL
  } else if (postMeta?.Type === 'youtube') {
    return `https://img.youtube.com/vi/${postMeta.Data}/0.jpg`
  }
  if (postMeta?.Type === 'file') {
    return postMeta.Data.Preview.URL
  }
  if (postMeta?.Type === 'article' && postMeta?.Data?.Image) {
    return postMeta?.Data?.Image
  }
  return `${siteURL}/static/images/banner.png`
}
