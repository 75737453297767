import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'

import Button, { ButtonAppearance } from '@/components/base/Button'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import CustomIcon, { IconsTypes } from '@/components/base/Icons/CustomIcon'
import { useAuthStore } from '@/dataStores/auth'
import useLike from '@/hooks/useLike'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import { isCompanyType } from '@/utils/companyTypes'
import { odsIcon } from '@/utils/ods'

import CommentForm from './CommentForm'
import CommentsList from './CommentsList'
import { LikeButton } from './LikeButton'
import { PostReactionList } from './PostReactionList'
import PostShareButton from './PostShareButton'

const PostInteractors = ({
  post,
  numberOfComments,
  flex,
  enableCommentHighlight,
  isInViewport,
  ods,
  showComments,
  mutatePost,
  canRepost = true,
}): JSX.Element => {
  const [user] = useAuthStore((state) => [state.user])

  const { handleToggle } = useLike({ post })
  const { authors } = useSunetAuthors()
  const [author, setAuthor] = useState(authors[0])
  const ownLike = post.Included.OwnLike
  const currentReaction = ownLike?.Slug
  const [reaction, setReaction] = useState(currentReaction || '')

  const isLikeDisabled = isCompanyType(author?.Type)

  const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max)
  }

  const [tempValue, setTempValue] = useState(getRandomInt(16) + 1)
  const selectedODS = ods ? odsIcon.find((od) => od.index === ods) : odsIcon.find((od) => od.index === tempValue)

  const showAnalytics =
    isCompanyType(post?.Included?.Author?.Type) &&
    user &&
    (user.IsAdmin || user.Companies?.find((c) => c.UUID === post.AuthorUUID))

  const handleChangeReaction = (slug) => {
    handleToggle(slug)
    if (reaction === slug) {
      setReaction(null)
    } else {
      setReaction(slug)
    }
  }

  const [likeCount, setLikeCount] = useState(post?.Included?.LikeCount || 0)

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex flex-col align-stretch mb-5  border-b border-gray-200">
        {selectedODS && ods > 0 ? (
          <div className="flex flex-col">
            <div className="flex flex-row justify-start items-center gap-2 py-3  mt-2">
              <div
                className="w-8 h-8 flex items-center justify-center rounded-md"
                style={{ backgroundColor: selectedODS.color }}
              >
                <Image alt="ods" src={selectedODS.image} height={32} width={32} className="p-1 self-center " />
              </div>
              <div className="flex flex-col">
                <CustomText weight={FontWeight.SemiBold}>ODS</CustomText>
                <CustomText size={TextSize.Caption}>{selectedODS.title()}</CustomText>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <PostReactionList post={post} postUUID={post.UUID} likeCount={likeCount} />
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <LikeButton
            post={post}
            onClick={handleChangeReaction}
            disabled={isLikeDisabled}
            reaction={reaction}
            setReaction={setReaction}
            setLikeCount={setLikeCount}
          />

          <Link href={`/p/${post.UUID}`} passHref>
            <Button appearance={ButtonAppearance.Gray} className="flex px-4 h-8 ml-2">
              <div className="flex items-center">
                <span className="block leading-none">
                  <CustomIcon name={IconsTypes.comment} />
                </span>
                <span className="block md:text-sm text-xs ml-2 leading-none">
                  {post.Included.CommentCount}{' '}
                  <CustomText size={TextSize.Caption} className="hidden lg:inline">
                    comentarios
                  </CustomText>
                </span>
              </div>
            </Button>
          </Link>
        </div>
        <PostShareButton post={post} canRepost={canRepost} />
      </div>

      {showComments ? (
        <div className="flex flex-col align-stretch flex-1 mt-5 pt-5 border-t border-gray-200">
          <CommentsList
            postUUID={post.UUID}
            lastComment={post.Included.LastComment}
            numberOfComments={numberOfComments}
            flex={flex}
            enableCommentHighlight={enableCommentHighlight}
            isInViewport={isInViewport}
            author={author}
          />
        </div>
      ) : null}
      {user?.IsActive ? <CommentForm postUUID={post.UUID} Author={author} mutatePost={mutatePost} /> : null}
    </div>
  )
}

export default PostInteractors

// {isUserValid ? <CommentForm postUUID={post.UUID} Author={author} /> : null}
