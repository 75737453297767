import { SvgIcon } from '@/components/base/Icons/CustomIcon'

export const Leaf = ({ size }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 257 257" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.767 76.4125L45.087 101.601C46.484 108.214 49.1535 114.492 52.948 120.086L63.202 135.204L41.323 150.044L28.1135 130.569C24.313 124.966 21.641 118.676 20.246 112.051L13.891 81.8695C12.387 74.7265 16.9555 67.7165 24.0975 66.208C31.242 64.699 38.2575 69.2675 39.767 76.4125Z"
        fill="#FFCDBE"
      />
      <path
        d="M100.61 172.161C97.706 167.88 93.982 164.488 89.79 162.051C85.895 159.786 82.508 156.744 79.9785 153.015L66.4185 133.023C62.3205 126.982 54.1005 125.406 48.059 129.504C42.0175 133.602 40.4415 141.822 44.5395 147.863L39.5935 140.571C35.7995 134.977 33.1295 128.699 31.7325 122.085L26.4125 96.897C24.9035 89.752 17.8875 85.183 10.7425 86.6925C3.60048 88.201 -0.968023 95.2115 0.535977 102.354L6.89098 132.536C8.28598 139.161 10.958 145.45 14.7585 151.053L31.5825 175.857C34.506 180.167 36.2435 185.171 36.62 190.365L39.7235 233.156C40.316 241.328 47.4205 247.471 55.592 246.879L92.581 244.196C100.752 243.604 106.896 236.499 106.303 228.328L104.784 207.385C104.568 204.41 104.801 201.419 105.481 198.514C107.533 189.753 106.059 180.195 100.61 172.161Z"
        fill="#FFEEE5"
      />
      <path
        d="M53.3519 127.396C51.5109 127.695 49.7039 128.387 48.0589 129.503C42.0174 133.601 40.4414 141.821 44.5394 147.862L60.3479 171.169C62.0724 173.711 65.5319 174.374 68.0744 172.65C70.6169 170.925 71.2799 167.466 69.5554 164.923L53.7469 141.617C50.7649 137.22 50.7904 131.672 53.3519 127.396Z"
        fill="#FFDECF"
      />
      <path
        d="M80.5605 218.25H47.715C39.45 218.25 32.75 224.95 32.75 233.215V241.285C32.75 249.55 39.45 256.25 47.715 256.25H81.9565L80.5605 218.25Z"
        fill="#636978"
      />
      <path
        d="M98.285 218.25H80.2715C74.1595 220.302 69.75 226.119 69.75 232.98V241.128C69.75 248.496 74.835 254.661 81.6535 256.25H98.285C106.55 256.25 113.25 249.55 113.25 241.285V233.215C113.25 224.95 106.55 218.25 98.285 218.25Z"
        fill="#707789"
      />
      <path
        d="M216.733 76.4125L211.413 101.601C210.016 108.214 207.346 114.492 203.552 120.086L193.298 135.204L215.177 150.044L228.386 130.569C232.187 124.966 234.859 118.676 236.254 112.051L242.609 81.8695C244.113 74.7265 239.544 67.7165 232.402 66.208C225.258 64.699 218.242 69.2675 216.733 76.4125Z"
        fill="#FFCDBE"
      />
      <path
        d="M155.89 172.161C158.794 167.88 162.518 164.488 166.71 162.051C170.605 159.786 173.992 156.744 176.521 153.015L190.081 133.023C194.179 126.982 202.399 125.406 208.441 129.504C214.482 133.602 216.058 141.822 211.96 147.863L216.906 140.571C220.7 134.977 223.37 128.699 224.767 122.085L230.087 96.897C231.596 89.752 238.612 85.183 245.757 86.6925C252.899 88.201 257.467 95.2115 255.963 102.354L249.608 132.536C248.213 139.161 245.541 145.45 241.741 151.053L224.917 175.857C221.993 180.167 220.256 185.171 219.879 190.365L216.776 233.156C216.183 241.328 209.079 247.471 200.907 246.879L163.918 244.196C155.747 243.604 149.603 236.499 150.196 228.328L151.715 207.385C151.931 204.41 151.698 201.419 151.018 198.514C148.966 189.753 150.44 180.195 155.89 172.161Z"
        fill="#FFEEE5"
      />
      <path
        d="M203.148 127.396C204.989 127.695 206.796 128.387 208.441 129.503C214.482 133.601 216.058 141.821 211.96 147.862L196.152 171.169C194.427 173.711 190.968 174.374 188.425 172.65C185.883 170.925 185.22 167.466 186.944 164.923L202.753 141.617C205.735 137.22 205.709 131.672 203.148 127.396Z"
        fill="#FFDECF"
      />
      <path
        d="M190.561 218.25H157.715C149.45 218.25 142.75 224.95 142.75 233.215V241.285C142.75 249.549 149.45 256.249 157.715 256.249H191.956L190.561 218.25Z"
        fill="#636978"
      />
      <path
        d="M208.285 218.25H190.271C184.159 220.302 179.75 226.119 179.75 232.98V241.128C179.75 248.496 184.835 254.661 191.654 256.25H208.285C216.55 256.25 223.25 249.55 223.25 241.285V233.215C223.25 224.95 216.55 218.25 208.285 218.25Z"
        fill="#707789"
      />
      <path
        d="M79.4575 50.249C79.5175 55.934 80.2765 61.6895 81.8105 67.4145C87.6885 89.3505 103.561 106.046 123.53 114.247C129.678 116.772 136.786 114.867 140.848 109.606C154.041 92.5195 159.44 70.1245 153.562 48.1885C152.028 42.4635 149.808 37.0995 147.017 32.1465L79.4575 50.249Z"
        fill="#B4D241"
      />
      <path
        d="M111.843 1.35574C105.694 -1.16926 98.5865 0.735244 94.5245 5.99624C84.775 18.6237 79.289 34.1507 79.458 50.2487C88.1365 62.7792 104.041 69.1477 119.609 64.9767C135.177 60.8057 145.767 47.3372 147.017 32.1462C139.114 18.1202 126.599 7.41624 111.843 1.35574Z"
        fill="#C3DC4B"
      />
      <path
        d="M159.875 131.685C148.045 124.714 137.711 113.509 129.992 99.2818C125.625 91.2338 122.149 82.3938 119.577 72.8313L133.354 59.0538C134.819 57.5888 134.819 55.2148 133.354 53.7503C131.89 52.2863 129.515 52.2863 128.051 53.7503L117.558 64.2438C116.088 57.0513 115.083 49.4993 114.566 41.5993C114.431 39.5323 112.645 37.9678 110.579 38.1018C108.513 38.2373 106.947 40.0223 107.082 42.0888C108.596 65.2488 114.086 85.6947 123.399 102.859C131.768 118.283 143.064 130.485 156.067 138.147C156.665 138.499 157.32 138.666 157.967 138.666C159.251 138.666 160.502 138.006 161.201 136.82C162.253 135.035 161.659 132.737 159.875 131.685Z"
        fill="#8CA52D"
      />
    </svg>
  )
}
