import { useState } from 'react'

import { useAlert } from '@/providers/alert/context'
import { deleteCommentByUUID } from '@/services/postsService'

type Props = {
  uuid: string
  onDelete?: () => void
}

type ReturnProps = {
  handleClick: () => void
  loading?: boolean
}

const useDeleteComment = ({ uuid, onDelete }: Props): ReturnProps => {
  const { showConfirm } = useAlert()

  const [loading, setLoading] = useState<boolean>(false)

  const deleteComment = async () => {
    setLoading(true)
    try {
      await deleteCommentByUUID(uuid)
      onDelete && (await onDelete())
    } catch (error) {
      console.error('TODO: DELETE COMMENT ERROR')
    } finally {
      setLoading(false)
    }
  }

  const handleClick = () => {
    showConfirm({
      title: `Confirmar acción`,
      content: <span>Estás a un paso de eliminar un comentario. ¿Estás seguro/a?</span>,
      onConfirm: deleteComment,
    })
  }

  return { handleClick, loading }
}

export default useDeleteComment
