import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import { FancyModal } from '@/components/base/FancyModal'
import Spinner from '@/components/base/Spinner'
import useRequest2 from '@/hooks/useRequest2'
import { findPostLikesAndAuthorsByPostUUID } from '@/services/postsService'
import { getAuthorProfile } from '@/utils/authors'
import { getPostReactionIcon } from '@/utils/posts'

import Avatar from '../Avatar'

export const PostReactionList = ({ postUUID, post, likeCount }) => {
  const topReactions = post?.Included?.TopThreeLikes || []

  const [open, setOpen] = useState(false)

  const [postLikes, loadingPostLikes, postLikesError, revalidatePostLikes] = useRequest2({
    requestKey: `/post/likes/${postUUID}`,
    params: [postUUID],
    request: (postUUID) => findPostLikesAndAuthorsByPostUUID(postUUID),
  })

  useEffect(() => {
    revalidatePostLikes()
  }, [post])

  const hasReactions = postLikes?.length > 0
  if (!hasReactions) {
    return
  }

  const reactionsLength = topReactions?.length || 0

  return (
    <div className="flex items-center mt-2">
      <button onClick={() => setOpen(true)} className="mb-2 flex items-center">
        <div className="relative pb-4 flex items-center">
          {topReactions?.map((r, index) => (
            <div
              key={r.Slug}
              className="rounded-full border border-gray-4 bg-white p-1.5 absolute"
              style={{ left: index * 16 }}
            >
              {getPostReactionIcon(r.Slug, 16)}
            </div>
          ))}
          <CustomText
            weight={FontWeight.SemiBold}
            className="text-black absolute"
            style={{ left: (reactionsLength + 1.5) * 16 }}
          >
            {likeCount}
          </CustomText>
        </div>
      </button>

      <FancyModal title="Reacciones" isOpen={open} setIsOpen={setOpen}>
        <section className="flex flex-col gap-2 max-h-[400px] h-[400px] overflow-y-scroll px-4">
          {loadingPostLikes && <Spinner size={200} />}
          {hasReactions && !loadingPostLikes
            ? postLikes.map((item) => <ReactionItem key={item.UUID} item={item} />)
            : null}
        </section>
      </FancyModal>
    </div>
  )
}

const ReactionItem = ({ item }) => {
  const { push } = useRouter()
  const likeAuthor = item.Included.Author

  const reaction = item.Slug

  const handleRedirect = () => {
    push(getAuthorProfile(likeAuthor))
  }

  return (
    <button onClick={handleRedirect} className="flex items-center gap-4 p-2 hover:bg-gray-2/20 rounded-xl">
      <div className="relative">
        <Avatar levelColor={likeAuthor?.LevelColor} className="h-16 w-16" image={likeAuthor?.Photo?.URL} />
        <div className="bg-white p-1.5 rounded-full absolute bottom-0 right-0 shadow-md">
          {getPostReactionIcon(reaction, 16)}
        </div>
      </div>
      <div className="flex flex-col gap-0 items-start">
        <CustomText weight={FontWeight.SemiBold}>{likeAuthor.Name}</CustomText>
        <CustomText size={TextSize.Caption}>
          {likeAuthor?.City ? `${likeAuthor?.City}, ` : ''}
          {likeAuthor?.Country || ''}
        </CustomText>
      </div>
    </button>
  )
}
