import clsx from 'clsx'

import CustomIcon, { IconsTypes } from '@/components/base/Icons/CustomIcon'

import { reactions } from './reactions'

export const getPostReactionIcon = (slug, size = 16) => {
  const currentReaction = reactions.find((r) => r.value === slug) || ''
  const isLike = slug === 'like'

  let Component
  if (!slug) {
    Component = <CustomIcon size={size} className={clsx('text-black')} name={IconsTypes.commentHeart} />
  } else {
    if (isLike) {
      Component = <CustomIcon size={size} className={clsx('text-primary')} name={IconsTypes.commentHeart} />
    }
    if (!isLike) {
      const IconComponent = currentReaction.icon
      Component = <IconComponent size={size} />
    }
  }
  return Component
}
