import clsx from 'clsx'

import Button, { ButtonAppearance } from '@/components/base/Button'
import CustomIcon, { IconsTypes } from '@/components/base/Icons/CustomIcon'
import { useAuthStore } from '@/dataStores/auth'
import useCommentLike from '@/hooks/useCommentLike'

const CommentLikeButton = ({ comment, author }): JSX.Element => {
  const [user] = useAuthStore((state) => [state.user])
  const isUserValid = user?.DeactivateReason || user?.IsActive
  const { handleToggle, ownLike, likeCount } = useCommentLike({
    comment,
    author,
  })
  const isLikeDisabled = author?.Type === 'company' || !isUserValid

  return (
    <Button
      appearance={ButtonAppearance.Gray}
      onClick={handleToggle}
      className={clsx('px-4 h-8', isLikeDisabled ? 'opacity-50' : null)}
      disabled={isLikeDisabled}
    >
      <div className={clsx('flex items-center', ownLike ? 'text-brand' : null)}>
        <span className="block leading-none">
          <CustomIcon className={clsx(!ownLike ? 'text-black' : 'text-primary')} name={IconsTypes.commentHeart} />
        </span>
        <span className="block ml-2 leading-none ">{likeCount}</span>
      </div>
    </Button>
  )
}

export default CommentLikeButton
