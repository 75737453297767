import clsx from 'clsx'
import React, { useState } from 'react'
import { Tooltip } from 'react-tippy'

import Button, { ButtonAppearance } from '@/components/base/Button'
import CustomText, { TextSize } from '@/components/base/CustomText'
import FloatingCard from '@/components/base/FloatingCard/FloatingCard'
import CustomIcon, { IconsTypes } from '@/components/base/Icons/CustomIcon'
import { SITE_URL } from '@/config'
import { useAuthStore } from '@/dataStores/auth'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import { globalI18n } from '@/providers/language/context'
import { getDescText, getTitleText } from '@/utils/getPostData'
import { getLocaleText } from '@/utils/i18n'

import PostFormModal from './PostFormModal'

function Trigger({ onClick }) {
  return (
    <Button appearance={ButtonAppearance.Gray} onClick={onClick} className={clsx('px-4 h-8')}>
      <div className="flex items-center">
        <span className="block leading-none">
          <CustomIcon className={clsx('text-black')} name={IconsTypes.sharePost} />
        </span>
        <span className="block md:text-sm text-xs ml-0 lg:ml-2 leading-none">
          <CustomText size={TextSize.Caption} className="hidden lg:inline">
            {' '}
            Compartir
          </CustomText>
        </span>
      </div>
    </Button>
  )
}

function dataToShare(post: any, url: any, locale = 'es') {
  const objToShare = {
    Title: getTitleText(post.Type, post.Content, post.Metadata, locale),
    Message: getDescText(post.Type, post.Content, post.Metadata, locale),
    Url: url,
  }
  return { shareData: JSON.stringify(objToShare), title: objToShare.Title }
}

export default function PostShareButton({ post, canRepost = true }) {
  const [user] = useAuthStore((state) => [state.user])

  const [open, setOpen] = useState(false)

  const actualUrl = `${SITE_URL}/p/${post.UUID}`
  const { handleClick: handleCopy } = useCopyToClipboard({ text: actualUrl })

  const { shareData, title } = dataToShare(post, actualUrl, globalI18n.locale)
  // const postsService = useService(PostsService)

  const chooseTrigger = (onclick) => {
    return <Trigger onClick={onclick} />
  }

  return (
    <div className="relative">
      <Tooltip animation="shift" touchHold={true} position="top" title={getLocaleText(`Compartir publicación`)}>
        <FloatingCard renderTrigger={(toggle) => chooseTrigger(toggle)}>
          <FloatingCard.Button
            onClick={() => setOpen(true)}
            icon="Share2"
            disabled={!canRepost}
            help={getLocaleText(`Podrás añadir un texto a la publicación`)}
          >
            <span className={clsx(!canRepost ? 'text-gray-2' : 'null')}>Compartir en Sunet</span>
          </FloatingCard.Button>

          <FloatingCard.Button
            onClick={handleCopy}
            icon="Copy"
            help={getLocaleText(`Se copiará el enlace a tu portapapeles`)}
          >
            Copiar enlace
          </FloatingCard.Button>
        </FloatingCard>
      </Tooltip>

      <PostFormModal
        open={open}
        onClose={() => setOpen(false)}
        preDefinedObjective={post.Extra.ODS}
        metadata={{
          Type: post.Type === 'initiative' ? 'initiative' : 'post',
          Data: post,
        }}
      />
    </div>
  )
}
