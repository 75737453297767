import { SvgIcon } from '@/components/base/Icons/CustomIcon'

export const Support = ({ size }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 256 249" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M163.597 131.666C190.113 116.099 217.5 86.0732 217.5 50.6212C217.5 22.826 194.331 0.207793 166.537 0.00144339C153.543 -0.0948759 141.656 4.63505 132.56 12.5048C130.946 13.9012 128.555 13.9012 126.941 12.5048C117.845 4.63505 105.958 -0.0948759 92.9644 0.00144339C91.7819 0.0103555 90.6075 0.0597153 89.4431 0.148151L70.7191 10.6466L74.6881 100.231L135.138 138.873L163.597 131.666Z"
        fill="#FD7A6E"
      />
      <path
        d="M81.428 38.2201C81.428 24.6713 84.2936 11.7933 89.4434 0.151367C63.2408 2.14528 42.0005 24.0087 42.0005 50.621C42.0005 102.009 101.14 143.096 129.75 143.096C138.891 143.096 151.148 138.974 163.597 131.665C117.253 125.754 81.428 86.171 81.428 38.2201Z"
        fill="#F85647"
      />
      <path
        d="M39.5174 69.1628L44.8374 94.3508C46.2344 100.964 48.9039 107.242 52.6984 112.836L62.9524 127.954L41.0734 142.794L27.8639 123.319C24.0634 117.716 21.3914 111.426 19.9964 104.801L13.6414 74.6198C12.1374 67.4768 16.7059 60.4668 23.8479 58.9583C30.9924 57.4493 38.0079 62.0178 39.5174 69.1628Z"
        fill="#FFCDBE"
      />
      <path
        d="M100.36 164.909C97.4565 160.628 93.7325 157.236 89.5405 154.799C85.6455 152.534 82.2585 149.492 79.729 145.763L66.169 125.771C62.071 119.73 53.851 118.154 47.8095 122.252C41.768 126.35 40.192 134.57 44.29 140.611L39.344 133.319C35.55 127.725 32.88 121.447 31.483 114.833L26.163 89.6453C24.654 82.5003 17.638 77.9313 10.493 79.4408C3.35097 80.9493 -1.21753 87.9598 0.286466 95.1023L6.64147 125.284C8.03647 131.909 10.7085 138.198 14.509 143.801L31.333 168.605C34.2565 172.915 35.994 177.919 36.3705 183.113L39.474 225.904C40.0665 234.076 47.171 240.219 55.3425 239.627L92.3315 236.944C100.503 236.352 106.646 229.247 106.054 221.076L104.535 200.133C104.319 197.158 104.552 194.167 105.232 191.262C107.284 182.501 105.81 172.943 100.36 164.909Z"
        fill="#FFEEE5"
      />
      <path
        d="M53.1024 120.147C51.2614 120.446 49.4544 121.138 47.8094 122.253C41.7679 126.351 40.1919 134.571 44.2899 140.613L60.0984 163.919C61.8229 166.462 65.2824 167.125 67.8249 165.4C70.3674 163.676 71.0304 160.216 69.3059 157.674L53.4974 134.367C50.5154 129.971 50.5409 124.422 53.1024 120.147Z"
        fill="#FFDECF"
      />
      <path
        d="M80.3109 211H47.4654C39.2004 211 32.5004 217.7 32.5004 225.965V234.035C32.5004 242.3 39.2004 249 47.4654 249H81.7069L80.3109 211Z"
        fill="#636978"
      />
      <path
        d="M98.0354 211H80.0219C73.9099 213.052 69.5004 218.87 69.5004 225.731V233.878C69.5004 241.246 74.5854 247.411 81.4039 249H98.0354C106.3 249 113 242.3 113 234.035V225.966C113 217.7 106.3 211 98.0354 211Z"
        fill="#707789"
      />
      <path
        d="M216.484 69.1628L211.164 94.3508C209.767 100.964 207.097 107.242 203.303 112.836L193.049 127.954L214.928 142.794L228.137 123.319C231.938 117.716 234.61 111.426 236.005 104.801L242.36 74.6198C243.864 67.4768 239.295 60.4668 232.153 58.9583C225.009 57.4493 217.993 62.0178 216.484 69.1628Z"
        fill="#FFCDBE"
      />
      <path
        d="M155.641 164.909C158.545 160.628 162.269 157.236 166.461 154.799C170.356 152.534 173.743 149.492 176.272 145.763L189.832 125.771C193.93 119.73 202.15 118.154 208.192 122.252C214.233 126.35 215.809 134.57 211.711 140.611L216.657 133.319C220.451 127.725 223.121 121.447 224.518 114.833L229.838 89.6453C231.347 82.5003 238.363 77.9313 245.508 79.4408C252.65 80.9493 257.218 87.9598 255.714 95.1023L249.359 125.284C247.964 131.909 245.292 138.198 241.492 143.801L224.668 168.605C221.744 172.915 220.007 177.919 219.63 183.113L216.527 225.904C215.934 234.076 208.83 240.219 200.658 239.627L163.669 236.944C155.498 236.352 149.354 229.247 149.947 221.076L151.466 200.133C151.682 197.158 151.449 194.167 150.769 191.262C148.717 182.501 150.191 172.943 155.641 164.909Z"
        fill="#FFEEE5"
      />
      <path
        d="M202.898 120.147C204.739 120.446 206.546 121.138 208.191 122.253C214.233 126.351 215.809 134.571 211.711 140.613L195.902 163.919C194.178 166.462 190.718 167.125 188.176 165.4C185.633 163.676 184.97 160.216 186.695 157.674L202.503 134.367C205.485 129.971 205.46 124.422 202.898 120.147Z"
        fill="#FFDECF"
      />
      <path
        d="M190.311 211H157.465C149.2 211 142.5 217.7 142.5 225.965V234.035C142.5 242.3 149.2 249 157.465 249H191.707L190.311 211Z"
        fill="#636978"
      />
      <path
        d="M208.035 211H190.022C183.91 213.052 179.5 218.87 179.5 225.731V233.878C179.5 241.246 184.585 247.411 191.404 249H208.035C216.3 249 223 242.3 223 234.035V225.966C223 217.7 216.3 211 208.035 211Z"
        fill="#707789"
      />
      <path
        d="M75.2428 75.2328C76.2242 79.4499 77.7371 83.6043 79.8211 87.6083C87.8066 102.95 102.36 112.748 118.559 115.555C123.547 116.42 128.518 113.832 130.672 109.251C137.665 94.3719 137.989 76.8305 130.004 61.4886C127.92 57.4846 125.385 53.8621 122.494 50.6392L75.2428 75.2328Z"
        fill="#B4D241"
      />
      <path
        d="M91.2653 33.5406C86.2775 32.6764 81.3062 35.2638 79.1529 39.845C73.9846 50.8408 72.4643 63.2899 75.2428 75.2317C83.7606 83.1185 96.6362 85.2328 107.524 79.5659C118.413 73.8991 124.067 62.1395 122.493 50.6382C114.306 41.5116 103.236 35.6151 91.2653 33.5406Z"
        fill="#C3DC4B"
      />
      <path
        d="M148.457 122.532C138.512 119.298 128.982 112.67 120.897 103.363C116.324 98.0988 112.283 92.0986 108.795 85.4123L116.768 72.8976C117.616 71.567 117.225 69.8018 115.894 68.9542C114.564 68.107 112.799 68.4983 111.951 69.8282L105.878 79.3598C103.6 74.2541 101.608 68.8045 99.9218 63.0156C99.4808 61.501 97.8954 60.6319 96.3813 61.072C94.8671 61.5133 93.9968 63.0986 94.4378 64.6129C99.3805 81.5839 106.832 95.8817 116.585 107.109C125.349 117.199 135.76 124.41 146.69 127.964C147.193 128.127 147.707 128.144 148.189 128.037C149.144 127.826 149.965 127.129 150.289 126.131C150.777 124.631 149.957 123.02 148.457 122.532Z"
        fill="#8CA52D"
      />
      <path
        d="M185.154 77.5603C183.723 81.6468 181.76 85.6101 179.233 89.3584C169.553 103.72 153.79 111.812 137.084 112.756C131.94 113.046 127.17 109.902 125.462 105.095C119.916 89.4817 121.404 71.9802 131.084 57.6182C133.61 53.8699 136.554 50.553 139.818 47.6747L185.154 77.5603Z"
        fill="#B4D241"
      />
      <path
        d="M173.234 34.22C178.378 33.9295 183.148 37.0738 184.856 41.881C188.954 53.4192 189.206 65.9874 185.154 77.5592C175.706 84.438 162.439 85.0715 151.992 78.1852C141.545 71.2988 137.033 58.9447 139.818 47.6737C149.06 39.5228 160.888 34.9173 173.234 34.22Z"
        fill="#C3DC4B"
      />
      <path
        d="M106.064 116.284C116.477 114.201 126.82 108.691 135.976 100.349C141.156 95.6302 145.872 90.1182 150.1 83.8599L143.315 70.4889C142.594 69.0672 143.173 67.3545 144.609 66.6627C146.045 65.9712 147.793 66.5625 148.514 67.9834L153.682 78.1673C156.519 73.3443 159.102 68.1462 161.41 62.5755C162.014 61.1179 163.71 60.4339 165.199 61.0451C166.688 61.6575 167.406 63.3353 166.802 64.7925C160.036 81.1242 151.005 94.5077 139.958 104.571C130.033 113.615 118.736 119.605 107.292 121.894C106.765 121.999 106.242 121.957 105.765 121.796C104.82 121.476 104.059 120.688 103.834 119.658C103.495 118.109 104.493 116.598 106.064 116.284Z"
        fill="#8CA52D"
      />
    </svg>
  )
}
