import { SvgIcon } from '@/components/base/Icons/CustomIcon'

export const Plant = ({ size }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 245 257" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M113.543 105.058H131.957V190.231H113.543V105.058Z" fill="#00C172" />
      <path
        d="M122.745 112.011C122.745 112.011 107.22 113.783 88.44 111.125C54.186 89.9446 54.186 24.1446 84.69 9.72461C93.045 13.3561 100.9 18.4091 107.225 25.3936C134.885 55.9541 122.745 112.011 122.745 112.011Z"
        fill="#60DAA8"
      />
      <path
        d="M88.44 111.125C71.2465 108.694 51.328 102.554 38.1055 87.9461C16.482 64.0511 19.181 24.5796 21.391 8.48859C21.973 4.22859 25.5325 1.01459 29.828 0.855086C41.3095 0.432586 64.4675 0.932586 84.69 9.72509C67.682 31.6521 67.543 87.5341 88.44 111.125Z"
        fill="#00CE8E"
      />
      <path
        d="M122.748 115.759C121.726 115.759 120.707 115.343 119.967 114.526L55.4194 43.2019C54.0299 41.6664 54.1484 39.2949 55.6834 37.9049C57.2194 36.5154 59.5904 36.6339 60.9804 38.1689L125.528 109.492C126.917 111.028 126.799 113.399 125.264 114.789C124.546 115.439 123.646 115.759 122.748 115.759Z"
        fill="#00C172"
      />
      <path
        d="M207.394 87.9467C201.769 94.1637 194.929 98.8457 187.605 102.359C136.291 97.0507 133.425 42.9852 160.243 9.97767C180.6 0.93767 204.082 0.42817 215.672 0.85567C219.968 1.01517 223.527 4.22967 224.109 8.48917C226.319 24.5802 229.018 64.0517 207.394 87.9467Z"
        fill="#60DAA8"
      />
      <path
        d="M187.605 102.359C158.914 116.138 122.755 112.012 122.755 112.012C122.755 112.012 110.614 55.9545 138.275 25.394C144.456 18.564 152.1 13.588 160.243 9.97754C148.267 40.033 151.548 84.1195 187.605 102.359Z"
        fill="#00CE8E"
      />
      <path
        d="M122.751 115.76C121.854 115.76 120.954 115.44 120.236 114.791C118.701 113.401 118.582 111.03 119.972 109.494L184.52 38.1703C185.91 36.6348 188.281 36.5163 189.817 37.9063C191.352 39.2963 191.471 41.6673 190.081 43.2033L125.533 114.526C124.793 115.344 123.774 115.76 122.751 115.76Z"
        fill="#00C172"
      />
      <path
        d="M229.94 256.751H164.467C102.212 250.788 88.44 190.23 119.933 176.16C121.184 176.113 122.436 176.093 123.693 176.093C166.414 176.093 211.412 207.07 239.454 230.636C250.029 239.516 243.75 256.751 229.94 256.751Z"
        fill="#F2AD9A"
      />
      <path
        d="M164.467 256.751H15.555C1.931 256.751 -4.4355 239.923 5.753 230.883C31.636 207.921 74.1055 177.747 119.933 176.16C104.526 194.992 111.557 240.083 164.467 256.751Z"
        fill="#E7896E"
      />
      <path
        d="M81.7715 235.962C90.1223 235.962 96.892 230.571 96.892 223.921C96.892 217.271 90.1223 211.88 81.7715 211.88C73.4207 211.88 66.651 217.271 66.651 223.921C66.651 230.571 73.4207 235.962 81.7715 235.962Z"
        fill="white"
      />
      <path
        d="M163.729 235.962C172.08 235.962 178.85 230.571 178.85 223.921C178.85 217.271 172.08 211.88 163.729 211.88C155.378 211.88 148.609 217.271 148.609 223.921C148.609 230.571 155.378 235.962 163.729 235.962Z"
        fill="white"
      />
      <path
        d="M90.5835 216.08C88.5125 216.08 86.8335 214.401 86.8335 212.33V206.149C86.8335 204.078 88.5125 202.399 90.5835 202.399C92.6545 202.399 94.3335 204.078 94.3335 206.149V212.33C94.3335 214.401 92.6545 216.08 90.5835 216.08Z"
        fill="#495560"
      />
      <path
        d="M154.917 216.08C152.846 216.08 151.167 214.401 151.167 212.33V206.149C151.167 204.078 152.846 202.399 154.917 202.399C156.988 202.399 158.667 204.078 158.667 206.149V212.33C158.667 214.401 156.988 216.08 154.917 216.08Z"
        fill="#495560"
      />
      <path
        d="M122.75 219.759C118.416 219.759 114.294 217.882 111.442 214.61C110.081 213.048 110.244 210.68 111.805 209.319C113.366 207.958 115.735 208.12 117.096 209.682C118.524 211.32 120.585 212.259 122.75 212.259C124.915 212.259 126.976 211.32 128.403 209.682C129.763 208.12 132.132 207.958 133.694 209.319C135.256 210.679 135.418 213.048 134.057 214.61C131.205 217.882 127.084 219.759 122.75 219.759Z"
        fill="#495560"
      />
    </svg>
  )
}
