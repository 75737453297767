import { useEffect, useState } from 'react'
import useIsInViewport from 'use-is-in-viewport'

import { useAuthStore } from '@/dataStores/auth'
import { readPostByPostUUIDAndAuthorUUID } from '@/services/postsService'
import { Post } from '@/ts/types/post.types'
import { registerInternalAnalyticsEvent } from '@/utils/analytics'

import useRequest from './useRequest'
import useTimeout from './useTimeout'

type Props = {
  post: Post
}

const usePostVisibility = ({ post }: Props) => {
  const { Author } = post?.Included || {}
  const [user] = useAuthStore((state) => [state.user])

  const [isInViewport, ref] = useIsInViewport({ threshold: 50 })
  const [eventSent, setEventSent] = useState(false)
  const { exec: execReadPost, error: errorReadPost } = useRequest(readPostByPostUUIDAndAuthorUUID)

  useTimeout(
    () => {
      if (user?.UUID && !errorReadPost) {
        execReadPost(post.UUID, user?.UUID)
      }
    },
    2000,
    isInViewport
  )

  useEffect(() => {
    if (!eventSent && isInViewport && post?.UUID && Author?.UUID && Author?.Type === 'company') {
      setEventSent(true)
      registerInternalAnalyticsEvent({
        companyUUID: Author.UUID,
        resourceUUID: post?.UUID,
        name: 'view-post',
        userUUID: user?.UUID,
      })
    }
  }, [isInViewport, Author, post])

  return { isInViewport, ref }
}

export default usePostVisibility
