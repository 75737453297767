import { useRouter } from 'next/router'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { mutate } from 'swr'

import { useAuthStore } from '@/dataStores/auth'
import { createCommentByPostUUIDAndAuthorUUIDAndContentAndMentionsAndHashtags } from '@/services/postsService'
import { extractHashtags } from '@/utils/hashtags'
import { mutateTokens } from '@/utils/mutation'

import useList from './useList'
import useSunetAuthors from './useSunetAuthors'

export default function useCommentSubmit({ postUUID, author, onSuccess }) {
  const [user] = useAuthStore((state) => [state.user])
  const { author: currentAuthor } = useSunetAuthors()
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const { mutateList } = useList({
    postUUID,
  })

  const handleSubmit = (values) => async (e) => {
    try {
      setLoading(true)
      e?.preventDefault()

      if (!values.Content) {
        return null
      }

      const mentions = values.Mentions?.map((mention) => ({
        AuthorUUID: mention.id.split(':')[1],
        Type: mention.id.split(':')[0],
        Start: mention.plainTextIndex,
        End: mention.plainTextIndex + mention.display.length,
      }))

      const hashtags = extractHashtags(values.PlainContent)

      const comment = await createCommentByPostUUIDAndAuthorUUIDAndContentAndMentionsAndHashtags(
        postUUID,
        author?.UUID,
        values.PlainContent,
        mentions,
        hashtags
      )
      if (comment !== null) {
        console.log('ENTER MUTATES')
        await mutateList()

        onSuccess && (await onSuccess(comment.UUID))
        await mutate([postUUID, currentAuthor?.UUID, '/posts/[uuid]'])
        await mutateTokens(currentAuthor.UUID)
      }
    } catch (e) {
      toast.error('Ocurrió un problema al intentar avanzar en la creacion de usuario.')
    } finally {
      setLoading(false)
    }
  }

  return { handleSubmit, loading }
}
