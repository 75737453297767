import Linkify from 'linkify-react'

import { sortByKey } from '@/utils/sort'
import { replaceSubStrings } from '@/utils/strings'

import WebViewCompatibleHashtagLink from './WebViewCompatibleHashtagLink'
import WebViewCompatibleMentionLink from './WebViewCompatibleMentionLink'

const WebViewCompatibleLinkify = ({ children, hashtags = [], mentions = [], options: _options }): JSX.Element => {
  const hashtagSubstrings =
    hashtags?.map((hashtag) => ({
      ...hashtag,
      renderComponent: (item, value) => <WebViewCompatibleHashtagLink key={value} item={item} value={value} />,
    })) || []

  const mentionSubstring =
    mentions?.map((mention) => ({
      ...mention,
      renderComponent: (item, value) => <WebViewCompatibleMentionLink key={value} item={item} value={value} />,
    })) || []

  const substrings = sortByKey([...hashtagSubstrings, ...mentionSubstring], 'Start', false)

  const options = {
    ..._options,
    attributes: {
      ..._options?.attributes,
    },
  }
  return <Linkify options={options}>{replaceSubStrings(children, substrings)}</Linkify>
}

export default WebViewCompatibleLinkify
