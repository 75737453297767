//import { t } from '@lingui/macro'

export const types = () => ({
  company: {
    company: `Empresa`,
    foundation: `Fundación o ONG`,
    government: `Gobierno`,
  },
  institution: `Institución`,
})

export const getMyCompanyType2 = (type, companyType) => {
  if (types()[type][companyType]) {
    return types()[type][companyType]
  }

  return types()[type]['company']
}

export const getMyCompanyType = (companyType) => {
  if (types()['company'][companyType]) {
    return types()['company'][companyType]
  }

  return types()['company']['company']
}

export const isCompanyType = (companyType) => {
  if (types()['company'][companyType]) {
    return true
  }
  return false
}
