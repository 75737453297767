import { SvgIcon } from '@/components/base/Icons/CustomIcon'

export const Flower = ({ size }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 207 257" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M111.939 250.861L103.25 251.336L94.561 250.861V186.374H111.939V250.861Z" fill="#00C172" />
      <path
        d="M135.835 33.3344C135.835 44.9084 129.803 60.0829 120.701 69.4424C80.7435 63.6304 81.716 8.87938 103.641 0.754883C121.459 0.960883 135.835 15.4659 135.835 33.3344Z"
        fill="#FF9EB1"
      />
      <path
        d="M120.701 69.4425C115.664 74.6345 109.673 78.0395 103.25 78.0395C85.2525 78.0395 70.6655 51.332 70.6655 33.335C70.6655 15.338 85.2525 0.75 103.25 0.75C103.379 0.75 103.512 0.75 103.641 0.755C90.8825 16.038 91.6965 52.2795 120.701 69.4425Z"
        fill="#FF899F"
      />
      <path
        d="M130.101 30.9601C117.376 43.6856 108.806 72.8871 121.532 85.6131C134.258 98.3391 163.459 89.7696 176.185 77.0441C188.91 64.3186 188.91 43.6861 176.185 30.9606C163.459 18.2346 142.827 18.2346 130.101 30.9601Z"
        fill="#FF899F"
      />
      <path
        d="M173.809 71.3086C155.812 71.3086 129.104 85.8981 129.104 103.895C129.104 121.891 155.812 136.481 173.809 136.481C191.805 136.481 206.395 121.891 206.395 103.895C206.395 85.8981 191.806 71.3086 173.809 71.3086Z"
        fill="#FF9EB1"
      />
      <path
        d="M176.185 130.747C163.459 118.021 134.258 109.452 121.532 122.178C108.806 134.904 117.375 164.105 130.101 176.831C142.826 189.556 163.459 189.556 176.184 176.831C188.911 164.105 188.911 143.473 176.185 130.747Z"
        fill="#FF899F"
      />
      <path
        d="M135.834 174.454C135.834 182.85 132.656 190.51 127.443 196.284C101.92 199.489 81.2165 165.934 102.817 129.76C102.961 129.749 103.105 129.749 103.249 129.749C121.247 129.75 135.834 156.457 135.834 174.454Z"
        fill="#FF9EB1"
      />
      <path
        d="M127.444 196.284C121.479 202.887 112.851 207.039 103.25 207.039C85.2525 207.039 70.6655 192.451 70.6655 174.454C70.6655 156.601 85.021 130.177 102.817 129.76C92.572 162.684 104.187 190.108 127.444 196.284Z"
        fill="#FF899F"
      />
      <path
        d="M30.3155 130.747C43.041 118.021 72.2425 109.452 84.9685 122.178C97.6945 134.904 89.125 164.105 76.3995 176.831C63.674 189.556 43.0415 189.556 30.316 176.831C17.5895 164.105 17.5895 143.473 30.3155 130.747Z"
        fill="#FF758F"
      />
      <path
        d="M32.691 71.3086C50.688 71.3086 77.3955 85.8981 77.3955 103.895C77.3955 121.891 50.6875 136.481 32.691 136.481C14.6945 136.481 0.10498 121.892 0.10498 103.895C0.10498 85.8976 14.694 71.3086 32.691 71.3086Z"
        fill="#FF899F"
      />
      <path
        d="M70.664 33.3354C70.664 31.0994 70.89 28.9159 71.319 26.8064C58.6675 18.4314 41.4595 19.8154 30.315 30.9599C17.5895 43.6854 17.5895 64.3179 30.315 77.0434C43.0405 89.7689 72.242 98.3383 84.968 85.6124C88.1005 82.4799 89.94 78.3484 90.7475 73.7114C78.956 65.4179 70.664 46.9024 70.664 33.3354Z"
        fill="#FF758F"
      />
      <path
        d="M156.572 103.897C156.572 119.211 150.112 133.02 139.775 142.74C74.1819 146.399 74.8665 63.6314 102.704 50.5804C132.337 50.2539 156.591 74.2574 156.572 103.897Z"
        fill="#FBF198"
      />
      <path
        d="M139.775 142.74C130.23 151.718 117.379 157.219 103.25 157.219C73.8025 157.219 49.928 133.345 49.928 103.897C49.928 74.6246 73.509 50.8736 102.704 50.5801C84.506 74.6341 83.1975 132.16 139.775 142.74Z"
        fill="#FAD989"
      />
      <path
        d="M145.694 253.167C127.127 262.188 103.25 251.337 103.25 251.337C103.25 251.337 109.476 225.859 128.043 216.838C142.236 209.942 159.532 214.659 166.894 217.264C168.979 218.002 170.078 220.271 169.37 222.366C166.867 229.764 159.885 246.272 145.694 253.167Z"
        fill="#60DAA8"
      />
      <path
        d="M78.457 216.837C64.264 209.941 46.968 214.658 39.6065 217.263C37.5215 218.001 36.422 220.27 37.1305 222.365C39.6335 229.764 46.615 246.272 60.806 253.166C79.373 262.187 103.25 251.336 103.25 251.336C103.25 251.336 97.024 225.858 78.457 216.837Z"
        fill="#00CE8E"
      />
      <path
        d="M81.217 105.573C79.146 105.573 77.467 103.894 77.467 101.823C77.467 100.389 76.3005 99.2227 74.8665 99.2227C73.4325 99.2227 72.266 100.389 72.266 101.823C72.266 103.894 70.587 105.573 68.516 105.573C66.445 105.573 64.766 103.894 64.766 101.823C64.766 96.2537 69.297 91.7227 74.8665 91.7227C80.436 91.7227 84.967 96.2537 84.967 101.823C84.967 103.895 83.288 105.573 81.217 105.573Z"
        fill="#495560"
      />
      <path
        d="M137.984 105.573C135.913 105.573 134.234 103.894 134.234 101.823C134.234 100.389 133.067 99.2227 131.633 99.2227C130.199 99.2227 129.033 100.389 129.033 101.823C129.033 103.894 127.354 105.573 125.283 105.573C123.212 105.573 121.533 103.894 121.533 101.823C121.533 96.2537 126.064 91.7227 131.633 91.7227C137.203 91.7227 141.734 96.2537 141.734 101.823C141.734 103.895 140.055 105.573 137.984 105.573Z"
        fill="#495560"
      />
      <path
        d="M103.25 116.065C99.5845 116.065 96.0985 114.478 93.6865 111.71C92.326 110.149 92.4885 107.78 94.05 106.419C95.611 105.058 97.98 105.221 99.341 106.782C100.328 107.915 101.753 108.565 103.25 108.565C104.747 108.565 106.172 107.915 107.16 106.782C108.52 105.221 110.889 105.058 112.451 106.419C114.012 107.78 114.175 110.149 112.814 111.71C110.401 114.478 106.915 116.065 103.25 116.065Z"
        fill="#495560"
      />
    </svg>
  )
}
