import { toast } from 'react-hot-toast'

type ReturnProps = {
  handleClick: () => void
}

type Props = {
  text: string
}

const useCopyToClipboard = ({ text }: Props): ReturnProps => {
  const handleClick = () => {
    // https://stackoverflow.com/questions/33855641/copy-output-of-a-javascript-variable-to-the-clipboard
    try {
      const element = document.createElement('textarea')
      document.body.appendChild(element)
      element.value = text
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)
      toast.success(`Enlace copiado con éxito`)
    } catch (e) {
      console.log('Unable to copy post URL to clipboard', e)
      toast.error(`Ocurrió un problema al copiar el enlace`)
    }
  }

  return { handleClick }
}

export default useCopyToClipboard
