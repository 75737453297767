import { SvgIcon } from '@/components/base/Icons/CustomIcon'

export const Recycle = ({ size }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 255 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80.8921 78.1502C80.3561 74.0297 77.9306 70.3943 74.3321 68.3168C70.7341 66.2388 66.3726 65.9562 62.5361 67.5522L27.9466 81.9383C21.1421 84.7683 17.9201 92.5788 20.7506 99.3833C23.3491 105.632 30.1461 108.846 36.5096 107.139L6.73656 158.705C-2.24544 174.262 -2.24544 192.837 6.73606 208.394C15.7176 223.951 31.8046 233.239 49.7681 233.239H62.4056C67.4906 233.239 71.6126 229.117 71.6126 224.032V215.758C71.6126 210.673 67.4906 206.551 62.4056 206.551H50.1411C41.8136 206.551 33.9851 202.239 29.8316 195.021C25.6906 187.825 25.6971 179.24 29.8486 172.049L59.6216 120.483C61.1766 126.293 66.4616 130.367 72.4791 130.367C80.4586 130.426 86.8216 123.215 85.7281 115.299L80.8921 78.1502Z"
        fill="#00CE8E"
      />
      <path
        d="M247.366 208.396C238.383 223.952 222.297 233.239 204.336 233.239H181.316C159.471 228.557 159.336 210.287 170.376 206.552H203.965C212.289 206.552 220.118 202.236 224.27 195.019C228.411 187.823 228.406 179.242 224.254 172.051L216.904 159.313C214.359 154.909 215.868 149.279 220.272 146.735L227.437 142.599C231.841 140.059 237.471 141.569 240.016 145.967L247.366 158.705C256.35 174.261 256.35 192.835 247.366 208.396Z"
        fill="#60DAA8"
      />
      <path
        d="M151.364 233.238C156.021 237.895 156.639 245.389 152.528 250.767C148.026 256.649 139.62 257.705 133.82 253.255L104.069 230.493C100.767 227.969 98.8301 224.049 98.8301 219.892C98.8301 215.741 100.767 211.821 104.069 209.297L133.82 186.535C139.672 182.059 148.047 183.171 152.528 189.023C156.639 194.4 156.021 201.895 151.364 206.551H170.376C165.818 213.5 167.718 226.861 181.317 233.238H151.364Z"
        fill="#00CE8E"
      />
      <path
        d="M221.643 74.1122L216.807 111.261C216.271 115.381 213.845 119.018 210.245 121.099C206.644 123.18 202.276 123.453 198.449 121.861L163.861 107.475C157.056 104.647 153.832 96.8327 156.665 90.0287C159.261 83.7807 166.06 80.5717 172.427 82.2767L146.971 38.1897C144.333 33.6262 140.378 30.2262 135.716 28.3517C120.687 20.6042 121.254 4.53569 132.502 0.304688C148.258 2.07119 162.341 11.4357 170.371 25.3482L195.533 68.9302C197.238 62.5637 203.424 58.2832 210.131 59.1587C217.44 60.1122 222.591 66.8082 221.643 74.1122Z"
        fill="#60DAA8"
      />
      <path
        d="M135.716 28.3517C133.022 27.2647 130.096 26.6882 127.052 26.6882C118.733 26.6882 111.29 30.9892 107.133 38.1902L104.099 43.4442C101.555 47.8482 95.9245 49.3572 91.5205 46.8127L84.3555 42.6767C79.9515 40.1322 78.4425 34.5022 80.987 30.0982L84.021 24.8442C93.061 9.18571 109.307 -0.122286 127.412 0.00121374C129.122 0.0117137 130.822 0.114714 132.501 0.305214C125.651 7.50571 124.497 20.4552 135.716 28.3517Z"
        fill="#00CE8E"
      />
      <path
        d="M115.178 87.499C120.566 78.1657 134.038 78.1657 139.426 87.499L179.913 157.624C185.302 166.957 178.566 178.624 167.789 178.624H86.8154C76.0382 178.624 69.3025 166.957 74.6911 157.624L115.178 87.499Z"
        fill="#00CE8E"
      />
      <path
        d="M113.564 96.6935C111.263 81.3987 131.807 74.2178 139.535 87.6157L179.91 157.613C186.341 168.762 175.584 181.952 163.369 177.894L131.955 167.457C126.953 165.796 123.308 161.466 122.524 156.254L113.564 96.6935Z"
        fill="#60DAA8"
      />
      <path
        d="M86.1726 172.562C94.5235 172.562 101.293 167.172 101.293 160.521C101.293 153.871 94.5235 148.48 86.1726 148.48C77.8218 148.48 71.0521 153.871 71.0521 160.521C71.0521 167.172 77.8218 172.562 86.1726 172.562Z"
        fill="#FFA1AC"
      />
      <path
        d="M168.131 172.562C176.481 172.562 183.251 167.172 183.251 160.521C183.251 153.871 176.481 148.48 168.131 148.48C159.78 148.48 153.01 153.871 153.01 160.521C153.01 167.172 159.78 172.562 168.131 172.562Z"
        fill="#FFA1AC"
      />
      <path
        d="M94.9846 152.68C92.9131 152.68 91.2346 151.001 91.2346 148.93V142.749C91.2346 140.678 92.9131 138.999 94.9846 138.999C97.0561 138.999 98.7346 140.678 98.7346 142.749V148.93C98.7346 151.001 97.0561 152.68 94.9846 152.68Z"
        fill="#495560"
      />
      <path
        d="M159.318 152.68C157.247 152.68 155.568 151.001 155.568 148.93V142.749C155.568 140.678 157.247 138.999 159.318 138.999C161.39 138.999 163.068 140.678 163.068 142.749V148.93C163.068 151.001 161.39 152.68 159.318 152.68Z"
        fill="#495560"
      />
      <path
        d="M127.152 156.36C122.818 156.36 118.696 154.483 115.844 151.21C114.483 149.649 114.646 147.28 116.208 145.919C117.769 144.558 120.137 144.721 121.499 146.282C122.926 147.92 124.987 148.859 127.152 148.859C129.318 148.859 131.378 147.92 132.806 146.282C134.167 144.721 136.535 144.558 138.097 145.919C139.658 147.28 139.821 149.649 138.46 151.21C135.607 154.483 131.486 156.36 127.152 156.36Z"
        fill="#495560"
      />
    </svg>
  )
}
