import clsx from 'clsx'
import { formatDistanceStrict } from 'date-fns'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import useSWR from 'swr'

import { useAuthStore } from '@/dataStores/auth'
import { findCommentByUUID } from '@/services/postsService'
import { getDateFnsLocale } from '@/utils/dates'

import Avatar from '../Avatar'
import CustomLink from '../CustomLink'
import ProfileLink from '../ProfileLink'
import CommentLikeButton from './CommentLikeButton'
import CommentOptions from './CommentOptions'
import WebViewCompatibleLinkify from './WebViewCompatibleLinkify'

const CommentItem = ({ comment: _comment, author }): JSX.Element => {
  const { user } = useAuthStore()
  const isUserValid = user?.DeactivateReason || user?.IsActive

  const { data: comment } = useSWR([_comment?.UUID, user?.UUID, '/comments/[uuid]'], findCommentByUUID, {
    initialData: _comment,
  })
  const { query } = useRouter()
  const Author = comment?.Author || _comment?.Included?.Author
  const ref = useRef(null)

  const isHighlighted = query?.commentUUID === comment?.UUID && query?.commentUUID !== undefined

  useEffect(() => {
    if (isHighlighted && ref) {
      ref.current.scrollIntoView({ bevahior: 'smooth' })
    }
  }, [])

  return (
    <div
      ref={ref}
      className={clsx(
        'group flex -mx-1 text-gray-800 p-2 mb-2 rounded-md border',
        isHighlighted ? ' border-primary bg-green-100' : 'border-gray-3'
      )}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-row w-full">
          <div className="flex px-1">
            <CustomLink href={`/profile/${Author?.Type?.substring(0, 1)}/${Author?.UUID}`}>
              <Avatar
                text={Author?.Name || 'test'}
                className="w-8 h-8 text-white text-xs"
                image={Author?.Photo?.Thumbnail}
              />
            </CustomLink>
          </div>
          <div className="flex-1 px-1 overflow-hidden-z">
            <div className="flex flex-col flex-1">
              <ProfileLink
                type={Author?.Type}
                uuid={Author?.UUID}
                className="hover:underline flex items-center leading-tight text-xs"
              >
                <b>{Author?.Name}</b>
                {Author?.IsCertified || Author?.Type === 'company' ? (
                  <Image
                    width={14}
                    height={14}
                    src="/static/images/certified.svg"
                    alt="Usuario verificado"
                    className="ml-2 inline-block"
                  />
                ) : null}
              </ProfileLink>
              <p className="leading-tight text-sm my-1 links break-words whitespace-pre-line">
                <WebViewCompatibleLinkify
                  mentions={comment?.Mentions}
                  hashtags={comment?.Hashtags}
                  options={{
                    target: { url: '_blank' },
                    attributes: { rel: 'noopener noreferrer' },
                  }}
                >
                  {comment?.Content}
                </WebViewCompatibleLinkify>
              </p>
            </div>

            <span className="block text-xs text-gray-500 leading-tight text-light mb-2">
              {comment?.CreatedAt
                ? formatDistanceStrict(new Date(comment?.CreatedAt), new Date(), {
                    locale: getDateFnsLocale(),
                    addSuffix: true,
                  })
                : ''}
            </span>
          </div>
          <div className="w-8">
            <div>
              {isUserValid ? (
                <CommentOptions uuid={comment?.UUID} AuthorUUID={Author?.UUID} postUUID={comment?.PostUUID} />
              ) : null}
            </div>
          </div>
        </div>

        <div>
          <CommentLikeButton comment={comment} author={author} />
        </div>
      </div>
    </div>
  )
}

export default CommentItem
