import useAuthor from '@/hooks/useAuthor'

import CustomLink from '../CustomLink'

const WebViewCompatibleMentionLink = ({ item, value }): JSX.Element => {
  const { author } = useAuthor({ uuid: value.AuthorUUID, type: value.Type })

  if (!author) {
    return <span className="text-brand">{item}</span>
  } else {
    return (
      <CustomLink href={author.URL} className="text-brand hover:underline">
        <span>{item}</span>
      </CustomLink>
    )
  }
}

export default WebViewCompatibleMentionLink
