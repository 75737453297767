import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import useSWR from 'swr'

import { useAuthStore } from '@/dataStores/auth'
import { findAllCommentsByPostUUID } from '@/services/postsService'

import usePrevious from './usePrevious'
import useValues from './useValues'

type Props = {
  postUUID: string
  numberOfComments?: number
  enableCommentHighlight?: boolean
  isInViewport?: boolean
}

type ReturnProps = {
  scroll
  data
  values
  updateValues
  lowerBound
  topBound
  mutateList
}

const useList = ({ postUUID, numberOfComments = 1, enableCommentHighlight, isInViewport }: Props): ReturnProps => {
  const { query } = useRouter()
  const { user } = useAuthStore()

  const { data, mutate: mutateList } = useSWR([postUUID, '/posts/comments'], findAllCommentsByPostUUID, {
    refreshInterval: isInViewport ? 5000 : 0,
    revalidateOnFocus: true,
  })

  const { values, updateValues } = useValues({
    showAll: enableCommentHighlight && !!query.commentUUID,
  })
  const oldData = usePrevious(data)

  const lowerBound = data?.length - numberOfComments > 0 ? data.length - numberOfComments : 0
  const topBound = data?.length || 0

  const scroll = useRef(null)

  useEffect(() => {
    if (oldData && data && data !== oldData && data[data.length - 1]?.UserUUID === user?.UUID) {
      scroll.current.scrollTop = scroll.current.scrollHeight
    }
  }, [oldData, data])

  return { scroll, data, values, updateValues, lowerBound, topBound, mutateList }
}

export default useList
