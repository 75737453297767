import { SvgIcon } from '@/components/base/Icons/CustomIcon'

export const Water = ({ size }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 211 257" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M169.108 243.775C192.798 231.453 208.994 209.102 210.672 172.824C211.712 147.947 194.554 109.496 173.229 78.2067C151.938 46.3752 127.961 19.9526 115.483 4.69869C112.174 0.818517 107.029 -0.523294 102.45 0.672746L64.6448 45.2461L56.9447 231.899L169.108 243.775Z"
        fill="#C5F1F0"
      />
      <path
        d="M79.1584 88.8496C79.1584 56.7749 87.6285 26.6797 102.451 0.673828C100.03 1.30636 97.7666 2.64714 96.0168 4.69875C83.5398 19.9537 59.5628 46.3742 38.271 78.2068C16.9463 109.496 -0.211386 147.947 0.828067 172.824C3.77181 236.455 51.3801 257.241 105.75 256.214C129.123 256.656 151.242 253.061 169.105 243.769C115.371 213.048 79.1584 155.178 79.1584 88.8496Z"
        fill="#A0E7E7"
      />
      <path
        d="M129.228 212.744C151.551 191.942 150.541 172.159 150.541 172.159C150.541 158.865 138.087 148.087 122.811 148.087C117.659 148.087 112.84 149.316 108.712 151.452C106.85 152.415 104.579 152.415 102.717 151.452C98.7556 149.402 94.1574 148.187 89.2398 148.093L79.158 173.432L108.494 212.744H129.228Z"
        fill="#EC9EB0"
      />
      <path
        d="M89.2399 148.105C89.0323 148.101 88.8273 148.087 88.6187 148.087C73.3431 148.087 60.9599 158.865 60.9599 172.159C60.9599 172.159 59.5593 199.57 96.465 223.789C102.011 227.428 109.491 227.428 115.036 223.789C120.591 220.143 125.272 216.427 129.226 212.741C111.607 194.505 97.8398 172.524 89.2399 148.105Z"
        fill="#E8899E"
      />
      <path
        d="M63.6095 131.84C72.197 131.84 79.1585 126.296 79.1585 119.457C79.1585 112.618 72.197 107.074 63.6095 107.074C55.022 107.074 48.0604 112.618 48.0604 119.457C48.0604 126.296 55.022 131.84 63.6095 131.84Z"
        fill="#F9FBF7"
      />
      <path
        d="M147.891 131.84C156.478 131.84 163.44 126.296 163.44 119.457C163.44 112.618 156.478 107.074 147.891 107.074C139.303 107.074 132.342 112.618 132.342 119.457C132.342 126.296 139.303 131.84 147.891 131.84Z"
        fill="#F9FBF7"
      />
      <path
        d="M80.0722 109.845C77.9382 109.845 76.209 108.116 76.209 105.982C76.209 104.031 74.622 102.444 72.6709 102.444C70.7202 102.444 69.1332 104.031 69.1332 105.982C69.1332 108.116 67.4041 109.845 65.27 109.845C63.136 109.845 61.4069 108.116 61.4069 105.982C61.4069 99.7708 66.4599 94.7178 72.6709 94.7178C78.8823 94.7178 83.9354 99.7708 83.9354 105.982C83.9354 108.116 82.2062 109.845 80.0722 109.845Z"
        fill="#585858"
      />
      <path
        d="M146.23 109.845C144.096 109.845 142.367 108.116 142.367 105.982C142.367 104.031 140.78 102.444 138.829 102.444C136.878 102.444 135.291 104.031 135.291 105.982C135.291 108.116 133.562 109.845 131.428 109.845C129.294 109.845 127.565 108.116 127.565 105.982C127.565 99.7708 132.618 94.7178 138.829 94.7178C145.04 94.7178 150.093 99.7708 150.093 105.982C150.093 108.116 148.363 109.845 146.23 109.845Z"
        fill="#585858"
      />
      <path
        d="M105.75 122.073C101.625 122.073 97.7017 120.286 94.9871 117.171C93.5851 115.563 93.7535 113.123 95.3616 111.721C96.9697 110.319 99.4102 110.486 100.812 112.095C102.059 113.526 103.859 114.346 105.75 114.346C107.642 114.346 109.442 113.526 110.689 112.095C112.091 110.486 114.531 110.319 116.139 111.721C117.747 113.123 117.915 115.563 116.514 117.171C113.798 120.286 109.875 122.073 105.75 122.073Z"
        fill="#585858"
      />
    </svg>
  )
}
