import { useCallback, useEffect, useRef } from 'react'

const useTimeout = (callback, timeout, enabled): number => {
  const timeoutIdRef = useRef()

  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current
    if (timeoutId) {
      timeoutIdRef.current = undefined
      clearTimeout(timeoutId)
    }
  }, [timeoutIdRef])

  useEffect(() => {
    if (enabled) {
      timeoutIdRef.current = setTimeout(callback, timeout)
    } else {
      cancel()
    }
    return cancel
  }, [callback, timeout, cancel])

  return cancel
}

export default useTimeout
