import Link from 'next/link'

const WebViewCompatibleHashtagLink = ({ item, value }): JSX.Element => {
  return (
    <Link href={`/tags/${value?.Value}`}>
      <div className="text-brand hover:underline">{item}</div>
    </Link>
  )
}

export default WebViewCompatibleHashtagLink
