import clsx from 'clsx'

import Button, { ButtonAppearance } from '@/components/base/Button'
import useList from '@/hooks/useList'

import CommentItem from './CommentItem'
import { useRouter } from 'next/router'

const CommentsList = ({
  lastComment,
  postUUID,
  numberOfComments = 1,
  flex,
  enableCommentHighlight,
  isInViewport,
  author,
}): JSX.Element => {
  const { scroll, data, values, updateValues, lowerBound, topBound } = useList({
    postUUID,
    numberOfComments,
    enableCommentHighlight,
    isInViewport,
  })

  const router = useRouter()

  return (
    <div className="flex flex-col flex-1">
      {data?.length > 1 && data.length > numberOfComments ? (
        <div>
          <Button appearance={ButtonAppearance.White} onClick={() => updateValues('showAll', !values.showAll)}>
            {router.pathname !== '/' && values.showAll ? `Mostrar menos comentarios` : `Mostrar todos los comentarios`}
          </Button>
        </div>
      ) : null}

      <div
        ref={scroll}
        className={clsx(flex ? 'flex-1 lg:flex-min-1 overflow-y-auto overflow-x-hidden  min-h-0' : null)}
      >
        {values.showAll
          ? data?.map((comment) => <CommentItem key={comment.UUID} comment={comment} author={author} />)
          : null}

        {!values.showAll && data?.length > 0
          ? data
              .slice(lowerBound, topBound)
              .map((comment) => <CommentItem key={comment.UUID} comment={comment} author={author} />)
          : null}

        {!values.showAll && (!data || data.length === 0) && lastComment ? (
          <CommentItem comment={lastComment} author={author} />
        ) : null}
      </div>
    </div>
  )
}

export default CommentsList
