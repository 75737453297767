import React, { useRef } from 'react'

import Modal from '@/components/base/CustomModal'
import LoadingWrapper from '@/components/base/LoadingWrapper'
import usePostUploader from '@/hooks/usePostUploader'

import PostForm from '../../Feed/PostForm'

export default function PostFormModal({ open, onClose, metadata, allowImages, zIndex = 100 }) {
  const { handleSubmit, loading, objective, setObjective } = usePostUploader({
    onSuccess: onClose,
    customKey: 'default',
    namespace: 'feed',
  })
  const formRef = useRef()
  return (
    <Modal
      zIndex={zIndex}
      open={open}
      onClose={onClose}
      maxWidth={700}
      closeOnOutsideClick={false}
      animate
      showCloseButton
    >
      <LoadingWrapper loading={loading}>
        <PostForm
          resetOnCreate={false}
          allowImages={allowImages}
          metadata={metadata}
          onCreate={handleSubmit}
          objective={objective}
          setObjective={setObjective}
          zIndex={zIndex + 50}
          formRef={formRef}
        />
      </LoadingWrapper>
    </Modal>
  )
}
