import { mutate } from 'swr'

import { toggleCommentLikeByCommentUUIDAndAuthorUUID } from '@/services/postsService'
import { CommentType } from '@/ts/types/general.types'
import { Author } from '@/ts/types/post.types'

import useRequest from './useRequest'

type Props = {
  comment: CommentType
  author: Author
}

type ReturnProps = {
  ownLike
  likeCount
  handleToggle
}

const useCommentLike = ({ comment, author }: Props): ReturnProps => {
  const { exec } = useRequest(toggleCommentLikeByCommentUUIDAndAuthorUUID)

  const handleToggle = async () => {
    const newOwnLike = !comment?.Included?.OwnLike
    const oldLikeCount = comment?.LikeCount
    const newLikeCount = newOwnLike ? oldLikeCount + 1 : oldLikeCount - 1

    await mutate(
      [comment?.UUID, author.UUID, '/comments/[uuid]'],
      {
        ...comment,
        LikeCount: newLikeCount,
        Included: {
          ...comment.Included,
          OwnLike: newOwnLike,
        },
      },
      false
    )
    await exec(comment.UUID, author.UUID)
    await mutate([comment.UUID, author.UUID, '/comments/[uuid]'])
  }

  return {
    ownLike: comment?.Included?.OwnLike || false,
    likeCount: comment?.LikeCount,
    handleToggle,
  }
}

export default useCommentLike
