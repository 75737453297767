import { SvgIcon } from '@/components/base/Icons/CustomIcon'

export const Light = ({ size }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 221 257" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M83.701 256.25C95.2349 256.25 104.585 246.9 104.585 235.366C104.585 223.833 95.2349 214.482 83.701 214.482C72.1671 214.482 62.817 223.833 62.817 235.366C62.817 246.9 72.1671 256.25 83.701 256.25Z"
        fill="#555A66"
      />
      <path
        d="M96.1365 237.242H71.265C61.0645 237.242 52.7955 228.973 52.7955 218.773V201.694C52.7955 198.642 55.2695 196.168 58.3215 196.168H109.08C112.132 196.168 114.606 198.642 114.606 201.694V218.773C114.606 228.973 106.337 237.242 96.1365 237.242Z"
        fill="#E1F1FA"
      />
      <path
        d="M96.8715 237.227C96.6295 237.238 96.382 237.243 96.135 237.243H71.2665C61.0625 237.243 52.7955 228.976 52.7955 218.772V201.697C52.7955 198.642 55.268 196.17 58.3225 196.17H84.664C81.6095 196.17 79.137 198.642 79.137 201.697V218.772C79.1375 228.728 87.0075 236.841 96.8715 237.227Z"
        fill="#CDEBFC"
      />
      <path
        d="M126.662 161.516H73.399L83.701 21.7305C83.701 9.8675 93.318 0.25 105.181 0.25C117.045 0.25 126.662 9.8675 126.662 21.731V161.516Z"
        fill="#FFEE6E"
      />
      <path
        d="M115.484 2.8785C112.425 1.2035 108.915 0.25 105.182 0.25C93.318 0.25 83.7005 9.8675 83.7005 21.731L73.3985 161.517H104.304V21.731C104.305 13.6005 108.822 6.5265 115.484 2.8785Z"
        fill="#FFDF61"
      />
      <path
        d="M83.701 161.516H40.7395V21.731C40.7395 9.8675 50.3565 0.25 62.22 0.25C74.0835 0.25 83.701 9.8675 83.701 21.731V161.516Z"
        fill="#FFEE6E"
      />
      <path
        d="M72.522 2.8785C69.463 1.2035 65.9535 0.25 62.22 0.25C50.3565 0.25 40.739 9.8675 40.739 21.731V161.517H61.3425V21.731C61.343 13.6005 65.8605 6.5265 72.522 2.8785Z"
        fill="#FFDF61"
      />
      <path
        d="M106.784 217.625H60.6175C40.8415 217.625 24.8105 201.594 24.8105 181.818V170.329C24.8105 161.795 31.729 154.877 40.263 154.877H127.138C135.673 154.877 142.591 161.795 142.591 170.329V181.818C142.591 201.594 126.56 217.625 106.784 217.625Z"
        fill="#F5FAFC"
      />
      <path
        d="M91.525 217.625H60.6195C40.84 217.625 24.8105 201.595 24.8105 181.821V170.329C24.8105 161.794 31.728 154.877 40.263 154.877H71.1685C62.6335 154.877 55.716 161.794 55.716 170.329V181.821C55.716 201.595 71.7455 217.625 91.525 217.625Z"
        fill="#E1F1FA"
      />
      <path
        d="M220.568 175.812C220.568 151.786 205.61 131.262 184.501 123.021C127.064 136.221 128.209 215.344 184.501 228.603C205.61 220.363 220.568 199.838 220.568 175.812Z"
        fill="#C3DC4B"
      />
      <path
        d="M148.456 175.814C148.456 151.79 163.407 131.261 184.512 123.019C178.126 120.525 171.178 119.154 163.909 119.154C132.616 119.154 107.249 144.522 107.249 175.814C107.249 201.121 123.841 222.55 146.74 229.823L150.1 227.485L154.24 231.649C157.383 232.19 160.612 232.474 163.909 232.474C171.178 232.474 178.126 231.104 184.512 228.61C163.407 220.367 148.456 199.837 148.456 175.814Z"
        fill="#B4D241"
      />
      <path
        d="M160.7 198.512C164.631 190.574 163.437 182.258 157.554 176.375C153.308 172.129 146.231 168.855 138.921 168.066C138.921 168.065 138.921 168.065 138.921 168.064C136.545 167.807 134.144 167.812 131.809 168.133C130.181 168.357 128.902 169.636 128.678 171.264C127.369 180.787 131.294 191.384 136.92 197.01C142.803 202.893 151.119 204.087 159.057 200.155C159.411 199.98 159.731 199.749 160.008 199.473L160.004 199.469C160.284 199.191 160.523 198.87 160.7 198.512Z"
        fill="#E1F1FA"
      />
      <path
        d="M157.639 201.407C152.063 190.149 153.757 178.355 162.101 170.011C168.122 163.99 178.16 159.346 188.528 158.227C188.528 158.226 188.528 158.225 188.528 158.224C191.898 157.86 195.302 157.867 198.615 158.322C200.923 158.639 202.738 160.453 203.055 162.762C204.911 176.268 199.344 191.297 191.366 199.276C183.023 207.619 171.229 209.314 159.97 203.738C159.469 203.49 159.014 203.161 158.622 202.771L158.628 202.765C158.23 202.369 157.891 201.914 157.639 201.407Z"
        fill="#F5FAFC"
      />
      <path
        d="M188.583 172.793C187.118 171.329 184.744 171.329 183.28 172.793L156.282 199.791C156.204 199.869 156.131 199.952 156.061 200.036C156.033 200.062 156.003 200.086 155.975 200.113C150.019 206.069 146.74 213.987 146.74 222.41V229.822C149.173 230.595 151.677 231.208 154.24 231.648V222.41C154.24 215.99 156.74 209.955 161.279 205.416C161.356 205.339 161.427 205.258 161.496 205.176C161.525 205.148 161.557 205.123 161.586 205.095L188.583 178.097C190.047 176.632 190.047 174.258 188.583 172.793Z"
        fill="#CDEBFC"
      />
      <path
        d="M162.717 64.3906H147.265C145.194 64.3906 143.515 62.7121 143.515 60.6406C143.515 58.5691 145.194 56.8906 147.265 56.8906H162.717C164.788 56.8906 166.467 58.5691 166.467 60.6406C166.467 62.7121 164.788 64.3906 162.717 64.3906Z"
        fill="#FFEE6E"
      />
      <path
        d="M148.396 48.9396C147.437 48.9396 146.477 48.5736 145.745 47.8416C144.28 46.3766 144.28 44.0026 145.745 42.5381L151.208 37.0746C152.673 35.6106 155.047 35.6106 156.511 37.0746C157.976 38.5396 157.976 40.9136 156.511 42.3781L151.048 47.8416C150.315 48.5736 149.356 48.9396 148.396 48.9396Z"
        fill="#FFEE6E"
      />
      <path
        d="M153.859 85.3067C152.899 85.3067 151.94 84.9407 151.208 84.2087L145.744 78.7452C144.28 77.2802 144.28 74.9062 145.744 73.4418C147.209 71.9778 149.583 71.9778 151.047 73.4418L156.51 78.9053C157.975 80.3703 157.975 82.7442 156.51 84.2087C155.778 84.9407 154.819 85.3067 153.859 85.3067Z"
        fill="#FFEE6E"
      />
      <path
        d="M20.135 64.3906H4.68201C2.61101 64.3906 0.932007 62.7121 0.932007 60.6406C0.932007 58.5691 2.61101 56.8906 4.68201 56.8906H20.135C22.206 56.8906 23.885 58.5691 23.885 60.6406C23.885 62.7121 22.206 64.3906 20.135 64.3906Z"
        fill="#FFEE6E"
      />
      <path
        d="M19.003 48.9396C18.0435 48.9396 17.0835 48.5736 16.3515 47.8416L10.888 42.3781C9.42349 40.9131 9.42349 38.5391 10.888 37.0746C12.3525 35.6106 14.727 35.6106 16.191 37.0746L21.655 42.5376C23.1195 44.0026 23.1195 46.3766 21.655 47.8411C20.9225 48.5736 19.963 48.9396 19.003 48.9396Z"
        fill="#FFEE6E"
      />
      <path
        d="M13.54 85.3067C12.5805 85.3067 11.6205 84.9407 10.8885 84.2087C9.42404 82.7437 9.42404 80.3698 10.8885 78.9053L16.352 73.4418C17.8165 71.9778 20.191 71.9778 21.655 73.4418C23.1195 74.9068 23.1195 77.2807 21.655 78.7452L16.1915 84.2087C15.4595 84.9407 14.4995 85.3067 13.54 85.3067Z"
        fill="#FFEE6E"
      />
    </svg>
  )
}
