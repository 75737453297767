import { SvgIcon } from '@/components/base/Icons/CustomIcon'

export const Panel = ({ size }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 287 287" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M153.553 223.923V269.878H133.448V223.923H153.553Z" fill="#6B7D8E" />
      <path
        d="M177.534 32.6933C176.432 32.6933 175.33 32.2734 174.489 31.4331C172.806 29.7519 172.804 27.024 174.485 25.34L179.038 20.7815C180.719 19.0975 183.447 19.0964 185.131 20.7776C186.814 22.4588 186.816 25.1867 185.135 26.8707L180.582 31.4292C179.741 32.2717 178.637 32.6933 177.534 32.6933Z"
        fill="#F9EE80"
      />
      <path
        d="M95.3474 66.7267H88.927C86.5477 66.7267 84.6189 64.7979 84.6189 62.4185C84.6189 60.0392 86.5477 58.1104 88.927 58.1104H95.3474C97.7268 58.1104 99.6556 60.0392 99.6556 62.4185C99.6562 64.7979 97.7268 66.7267 95.3474 66.7267Z"
        fill="#F9EE80"
      />
      <path
        d="M198.072 66.7267H191.652C189.272 66.7267 187.344 64.7979 187.344 62.4185C187.344 60.0392 189.273 58.1104 191.652 58.1104H198.072C200.452 58.1104 202.38 60.0392 202.38 62.4185C202.381 64.7979 200.452 66.7267 198.072 66.7267Z"
        fill="#F9EE80"
      />
      <path
        d="M143.5 91.3079C159.456 91.3079 172.391 78.3729 172.391 62.4167C172.391 46.4604 159.456 33.5254 143.5 33.5254C127.544 33.5254 114.609 46.4604 114.609 62.4167C114.609 78.3729 127.544 91.3079 143.5 91.3079Z"
        fill="#F9EE80"
      />
      <path
        d="M143.5 18.5748C141.121 18.5748 139.192 16.646 139.192 14.2666V7.84624C139.192 5.46688 141.121 3.53809 143.5 3.53809C145.88 3.53809 147.808 5.46688 147.808 7.84624V14.2666C147.808 16.646 145.88 18.5748 143.5 18.5748Z"
        fill="#F9EE80"
      />
      <path
        d="M109.466 32.6932C108.362 32.6932 107.259 32.2717 106.417 31.4291L101.865 26.8706C100.184 25.1872 100.186 22.4593 101.87 20.7775C103.553 19.0957 106.281 19.098 107.963 20.782L112.515 25.3405C114.196 27.0239 114.194 29.7518 112.51 31.4336C111.669 32.2734 110.568 32.6932 109.466 32.6932Z"
        fill="#F9EE80"
      />
      <path
        d="M282.905 24.9693H269.175C268.108 24.9693 267.315 23.9828 267.544 22.9412L271.982 2.78669C272.365 1.04358 270.115 -0.00418544 269.028 1.41105L243.726 34.3484C242.039 36.5443 243.604 39.721 246.373 39.721H260.103C261.17 39.721 261.963 40.7074 261.734 41.7491L257.296 61.9036C256.913 63.6467 259.163 64.6945 260.251 63.2792L285.552 30.3419C287.24 28.146 285.674 24.9693 282.905 24.9693Z"
        fill="#FFA93F"
      />
      <path
        d="M4.09507 24.9688H17.8253C18.892 24.9688 19.6849 23.9824 19.4558 22.9408L15.0182 2.78571C14.6346 1.0426 16.8851 -0.005162 17.9725 1.41007L43.2744 34.3474C44.9612 36.5433 43.3959 39.72 40.6268 39.72H26.896C25.8293 39.72 25.0364 40.7065 25.2656 41.7481L29.7031 61.9026C30.0867 63.6457 27.8362 64.6935 26.7488 63.2783L1.44695 30.3415C-0.239841 28.1456 1.32595 24.9688 4.09507 24.9688Z"
        fill="#FFA93F"
      />
      <path
        d="M241.156 201.807V219.04C241.156 228.558 233.44 236.273 223.923 236.273H63.0776C53.5597 236.273 45.8445 228.557 45.8445 219.04V201.807H241.156Z"
        fill="#99B2E9"
      />
      <path
        d="M264.835 213.295H209.561C121.954 199.378 80.9091 157.17 113.709 81.1719H245.855C251.438 81.1719 256.218 85.1873 257.172 90.6904L276.157 199.836C277.375 206.867 271.969 213.295 264.835 213.295Z"
        fill="#B5E9F1"
      />
      <path
        d="M209.561 213.294H22.1653C15.0306 213.294 9.62502 206.866 10.8429 199.835L29.828 90.6904C30.7816 85.1873 35.5609 81.1719 41.1449 81.1719H113.709C115.324 147.756 147.814 192.304 209.561 213.294Z"
        fill="#9BE1EC"
      />
      <path
        d="M280.397 199.101L261.417 89.956C260.096 82.3674 253.553 76.8643 245.855 76.8643H113.652C108.837 79.7745 108.97 82.6467 113.864 85.4811H139.192V120.907H118.983C112.791 124.533 114.199 127.349 121.499 129.524H139.192V164.485C138.722 170.163 142.04 173.37 147.809 174.98V173.561H204.656L207.735 208.987H198.085C206.121 212.313 214.726 215.185 223.889 217.604H264.835C269.511 217.604 273.917 215.553 276.927 211.974C279.931 208.396 281.201 203.708 280.397 199.101ZM147.808 85.4811H196.992L200.077 120.907H147.808V85.4811ZM147.808 164.944V129.524H200.824L203.903 164.944H147.808ZM205.644 85.4811H245.855C249.354 85.4811 252.329 87.9859 252.927 91.4326L258.056 120.908H208.723L205.644 85.4811ZM209.475 129.524H259.55L265.713 164.944H212.554L209.475 129.524ZM270.332 206.431C268.965 208.056 266.96 208.987 264.835 208.987H216.386L213.301 173.561H267.213L271.912 200.577C272.274 202.668 271.699 204.805 270.332 206.431Z"
        fill="#FFF8E6"
      />
      <path
        d="M198.084 208.987H147.809V174.98C144.73 171.636 141.863 168.138 139.192 164.485V164.944H83.0967L86.1758 129.524H121.499C120.58 126.715 119.741 123.843 118.983 120.907H86.923L90.0078 85.4811H113.864C113.732 82.649 113.664 79.7767 113.652 76.8643H41.1449C33.447 76.8643 26.8983 82.3674 25.5829 89.956L6.60331 199.101C5.79923 203.702 7.06892 208.395 10.0728 211.974C13.0828 215.553 17.4891 217.604 22.1647 217.604H223.887C214.726 215.185 206.12 212.313 198.084 208.987ZM70.6144 208.987H22.1653C20.0397 208.987 18.035 208.057 16.6677 206.431C15.3005 204.806 14.7261 202.668 15.088 200.577L19.7871 173.561H73.6986L70.6144 208.987ZM74.4458 164.944H21.2865L27.4448 129.524H77.525L74.4458 164.944ZM28.9437 120.907L34.0732 91.432C34.6704 87.9854 37.6464 85.4806 41.1449 85.4806H81.3563L78.2772 120.907H28.9437V120.907ZM139.192 208.987H79.2653L82.3444 173.561H139.192V208.987Z"
        fill="#FFEAB7"
      />
      <path
        d="M189.455 274.761V280.505C189.455 283.676 186.882 286.25 183.711 286.25H162.496C141.973 283.033 124.656 273.087 139.846 257.527H172.222C181.741 257.527 189.455 265.242 189.455 274.761Z"
        fill="#99B2E9"
      />
      <path
        d="M162.497 286.25H103.289C100.117 286.25 97.5439 283.676 97.5439 280.505V274.761C97.5439 265.242 105.259 257.527 114.777 257.527H139.846C140.013 272.417 149.492 282.705 162.497 286.25Z"
        fill="#85A4E4"
      />
      <path
        d="M116.726 150.159C116.729 157.081 109.685 162.696 100.994 162.7C92.302 162.704 85.2535 157.097 85.25 150.175C85.2465 143.253 92.29 137.638 100.981 137.634C109.673 137.63 116.722 143.237 116.726 150.159Z"
        fill="#FFA1AC"
      />
      <path
        d="M170.554 150.132C170.558 157.054 177.607 162.661 186.298 162.657C194.99 162.653 202.033 157.038 202.03 150.117C202.026 143.195 194.977 137.587 186.286 137.592C177.594 137.596 170.551 143.211 170.554 150.132Z"
        fill="#FFA1AC"
      />
      <path
        d="M159.064 144.472C159.059 134.938 152.152 134.045 143.632 134.05C135.112 134.054 128.205 134.953 128.21 144.487C128.215 154.021 135.126 161.746 143.646 161.742C152.166 161.738 159.068 154.006 159.064 144.472Z"
        fill="#495560"
      />
      <path
        d="M102.662 140.279C100.591 140.279 98.9129 138.601 98.9119 136.53C98.9104 133.528 100.078 130.704 102.2 128.58C104.322 126.456 107.145 125.286 110.147 125.284C113.153 125.284 115.974 126.452 118.097 128.573C120.221 130.695 121.392 133.517 121.394 136.52C121.395 138.591 119.717 140.27 117.646 140.272C115.573 140.272 113.895 138.594 113.894 136.524C113.893 135.524 113.504 134.585 112.797 133.879C112.09 133.173 111.152 132.785 110.153 132.785C108.088 132.786 106.411 134.465 106.412 136.528C106.413 138.599 104.735 140.278 102.663 140.279C102.663 140.279 102.662 140.279 102.662 140.279Z"
        fill="#495560"
      />
      <path
        d="M169.622 140.244C167.552 140.244 165.873 138.567 165.872 136.496C165.871 133.494 167.039 130.67 169.161 128.546C171.283 126.422 174.105 125.252 177.108 125.25C183.309 125.25 188.351 130.289 188.354 136.485C188.356 138.556 186.677 140.236 184.607 140.237C182.534 140.237 180.856 138.56 180.854 136.489C180.853 134.427 179.175 132.75 177.114 132.75C175.049 132.751 173.372 134.43 173.373 136.493C173.374 138.564 171.696 140.243 169.625 140.244C169.624 140.245 169.623 140.244 169.622 140.244Z"
        fill="#495560"
      />
    </svg>
  )
}
