export const TokenSmile = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.0001 61C22.8533 61 15.1941 57.8275 9.43339 52.0668C3.67266 46.3061 0.5 38.6469 0.5 30.4999C0.5 22.3531 3.67266 14.694 9.43339 8.93323C15.1941 3.17249 22.8533 0 31.0001 0C39.1469 0 46.8061 3.17249 52.5668 8.93323C58.3275 14.694 61.5001 22.3531 61.5001 30.4999C61.5001 38.6469 58.3275 46.3061 52.5668 52.0668C46.8061 57.8275 39.1469 61 31.0001 61Z"
        fill="#E8BD0A"
      />
      <path
        d="M58.4763 30.5023C58.4763 15.3277 46.1749 3.02637 31.0004 3.02637C15.8258 3.02637 3.52441 15.3277 3.52441 30.5023C3.52441 45.6768 15.8258 57.9782 31.0004 57.9782C46.1749 57.9782 58.4763 45.6768 58.4763 30.5023Z"
        fill="#FFDA33"
      />
      <path
        d="M36.1046 33.6917C36.1046 31.5995 33.2929 27.0774 31.8432 24.8849C31.4436 24.2806 30.5575 24.2806 30.1579 24.8849C28.7083 27.0774 25.8965 31.5995 25.8965 33.6917C25.8965 36.5106 28.1817 38.7958 31.0005 38.7958C33.8195 38.7958 36.1046 36.5106 36.1046 33.6917Z"
        fill="#E8BD0A"
      />
      <path
        d="M29.4032 42.6273C29.4032 42.1826 29.1141 41.7848 28.687 41.6607C25.2338 40.6569 22.7023 37.4656 22.7023 33.6928C22.7023 32.4661 23.0429 30.4817 25.6985 25.987C27.0252 23.7414 28.3359 21.8804 28.3911 21.8022L29.2182 20.6309C29.3385 20.4603 29.4032 20.2567 29.4032 20.048V8.54299C29.4032 7.67657 28.3845 7.21174 27.7302 7.77955L12.9655 20.5904C12.7443 20.7823 12.6172 21.0609 12.6172 21.3538V42.494C12.6172 42.8032 12.7588 43.0956 13.0015 43.2872L24.9101 52.6901C25.8858 53.4605 26.9712 54.0204 28.1063 54.3695C28.7516 54.5681 29.4032 54.075 29.4032 53.3998V42.6273Z"
        fill="#E8BD0A"
      />
      <path
        d="M32.5967 8.5439V20.0489C32.5967 20.2576 32.6614 20.4613 32.7817 20.6317L33.6088 21.8032C33.6639 21.8814 34.9747 23.7423 36.3014 25.9879C38.9568 30.4826 39.2974 32.467 39.2974 33.6937C39.2974 37.4665 36.766 40.6578 33.3128 41.6616C32.8858 41.7857 32.5967 42.1836 32.5967 42.6283V53.4008C32.5967 54.076 33.2483 54.569 33.8936 54.3704C35.0286 54.0213 36.1141 53.4615 37.0898 52.6911L48.9982 43.2882C49.241 43.0966 49.3825 42.8043 49.3825 42.4949V21.3548C49.3825 21.0619 49.2556 20.7833 49.0343 20.5913L34.2697 7.78046C33.6154 7.2128 32.5967 7.67749 32.5967 8.5439Z"
        fill="#E8BD0A"
      />
      <path
        d="M24.6792 33.3991C24.0906 33.3991 23.6135 32.922 23.6135 32.3334C23.6135 31.9259 23.2819 31.5943 22.8744 31.5943C22.4669 31.5943 22.1354 31.9259 22.1354 32.3334C22.1354 32.922 21.6582 33.3991 21.0696 33.3991C20.4811 33.3991 20.0039 32.922 20.0039 32.3334C20.0039 30.7506 21.2916 29.4629 22.8744 29.4629C24.4572 29.4629 25.7449 30.7506 25.7449 32.3334C25.7449 32.9221 25.2677 33.3991 24.6792 33.3991Z"
        fill="#495560"
      />
      <path
        d="M40.812 33.3991C40.2234 33.3991 39.7463 32.922 39.7463 32.3334C39.7463 31.9259 39.4147 31.5943 39.0072 31.5943C38.5997 31.5943 38.2682 31.9259 38.2682 32.3334C38.2682 32.922 37.791 33.3991 37.2024 33.3991C36.6139 33.3991 36.1367 32.922 36.1367 32.3334C36.1367 30.7506 37.4244 29.4629 39.0072 29.4629C40.59 29.4629 41.8777 30.7506 41.8777 32.3334C41.8777 32.9221 41.4006 33.3991 40.812 33.3991Z"
        fill="#495560"
      />
      <path
        d="M30.9411 36.3807C29.8994 36.3807 28.9087 35.9295 28.2232 35.143C27.8366 34.6992 27.8827 34.0261 28.3265 33.6393C28.7701 33.2526 29.4434 33.2987 29.8302 33.7425C30.1108 34.0645 30.5158 34.2491 30.9412 34.2491C31.3667 34.2491 31.7717 34.0645 32.0523 33.7425C32.4388 33.2987 33.1122 33.2526 33.556 33.6393C33.9997 34.026 34.0459 34.6992 33.6593 35.143C32.9735 35.9297 31.9828 36.3807 30.9411 36.3807Z"
        fill="#495560"
      />
    </svg>
  )
}
